<template>
  <ui-page no-back title="Activate your account">
    <div class="view-confirm">
      <div class="icon">
        <i class="far fa-spinner fa-spin"></i>
      </div>
    </div>
  </ui-page>
</template>

<script>

import api from '@/lib/api.js';

export default {
  name: 'ViewConfirm',

  props: {
    email: String,
    code: String,
  },

  mounted() {
    api.post('/user/confirm', {
      email: this.email,
      code: this.code,
    }).then((response) => {
      if(response.ok) {
        this.$store.commit('login', {
          email: this.email,
        })
      }
      this.$router.push('/choose');
    }).catch(() => {
      this.$router.push('/choose');
    });
  }

}

</script>

<style>
.view-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  width: 100%;
  flex-direction: column;
  color: #bdc3c7;
  font-size: 96px;
}
</style>