<template>
  <div class="assignment-image">
    <div class="assignment-image-overlay" v-if="showing" @click.self="hide">
      <div class="assignment-image-content" :class="{ rotated: rotated }">
        <div class="assignment-image-close" @click="hide">
          <i class="far fa-times"></i>
        </div>
        <img
          :src="imageUrl"
          :width="width"
          :height="height"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/lib/api.js';

export default {
  name: 'AssignmentImage',
  props: {
    image: Object,
  },
  data() {
    return {
      showing: false,
      rotated: false,
      width: this.image.width/10,
      height: this.image.height/10,
    };
  },

  computed: {
    imageUrl() {
      return api.url() + this.image.url;
    }
  },

  methods: {
    show() {
      this.recalculate();
      this.showing = true;
      window.addEventListener('resize', this.recalculate);
    },

    hide() {
      this.showing = false;
      window.removeEventListener('resize', this.recalculate);
    },

    recalculate() {
      var width = window.innerWidth - 40;
      var height = window.innerHeight - 40;
      const ratio = this.image.width / this.image.height;

      // Special case: rotate the image if on a mobile device
      this.rotated = false;
      if(window.innerWidth < 960 && window.innerWidth < window.innerHeight) {
        this.rotated = true;
        const tmp = width;
        width = height;
        height = tmp;
      }

      if(height > ratio * width) {
        this.width = width;
        this.height = width / ratio;
      } else {
        this.height = height;
        this.width = height * ratio;
      } 


    }
  }
}
</script>

<style>
.assignment-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}

.assignment-image-content {
  position: fixed;
}

.assignment-image-close {
  position: absolute;
  top: -20px;
  right: -20px;
  background: white;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bdc3c7;
  border-radius: 999px;
}
.assignment-image-close:hover {
  background: #ecf0f1;
}

.assignment-image-content.rotated{
  transform: rotate(90deg);
}

</style>
