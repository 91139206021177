<template>
  <div class="ui-dialog-overlay" @click.self="closeDialog">
    <div class="ui-dialog" :style="{ width: dialogWidth }">
      <div class="ui-dialog-header">
        <slot name="header">
          <div class="ui-dialog-title ellipsis">{{ title }}</div>
          <div class="ui-dialog-close" @click="closeDialog" v-show="!noClose">
            <i class="fas fa-times"></i>
          </div>
        </slot>
      </div>
      <div class="ui-dialog-content" lang="en">
        <slot></slot>
      </div>
      <div class="ui-dialog-buttons" v-if="!noButtons">
        <slot name="buttons">
          <ui-button
            :disabled="noClose"
            @click="closeDialog"
          >
            {{ closeButtonText }}
          </ui-button>
          <ui-button
            confirm
            :loading="loading"
            @click="confirmDialog"
          >
            {{ confirmButtonText }}
          </ui-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'UiDialog',

  props: {
    width: Number,
    title: String,
    closeButton: String,
    confirmButton: String,
    loading: Boolean,
    noClose: Boolean,
    noButtons: Boolean,
  },
  
  computed: {
    dialogWidth() {
      return this.width ? `${this.width}px` : 'auto';
    },

    closeButtonText() {
      return this.closeButton ?? 'Close';
    },

    confirmButtonText() {
      return this.confirmButton ?? 'Confirm';
    },
  },

  methods: {
    closeDialog() {
      if(!this.noClose) this.$emit('close');
    },

    confirmDialog() {
      this.$emit('confirm');
    }
  }
}
</script>

<style>
.ui-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}

.ui-dialog {
  background: white;
  border: 1px solid #bdc3c7;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 400px;
}

.ui-dialog-header {
  background: #ecf0f1;
  padding: 0 6px 0 12px;
  height: 40px;
  border-bottom: 1px solid #bdc3c7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}
.ui-dialog-title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 2px;
}
.ui-dialog-close {
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 999px;
  width: 32px;
  height: 32px;
  padding-top: 3px;
  text-align: center;
  flex-shrink: 0;
}
.ui-dialog-close:hover {
  background: #bdc3c7;
}

.ui-dialog-content {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
}
.ui-dialog-content P {
  text-align: justify;
  hyphens: auto;
  margin: 10px 0;
  line-height: 1.3em;
}

.ui-dialog-content .ui-message {
  margin-left: -10px;
  margin-right: -10px;
}

.ui-dialog-buttons {
  border-top: 1px solid #bdc3c7;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}
.ui-dialog-buttons BUTTON {
  min-width: 80px;
}

/*
 * Mobile styles start
 */

@media (max-width: 600px) {

  .ui-dialog {
    min-width: 100%;
    width: 100% !important;
    height: 100%;
    border-radius: 0;
    font-size: 1.3em;
  }

  .ui-dialog .ui-message-icon {
    display: none;
  }
  .ui-dialog .ui-message-text {
    margin-left: 0;
    border-left: none;
    padding: 5px 10px;
  }

  .ui-dialog-header {
    height: 60px;
  }
  .ui-dialog-title {
    font-size: 1.2em;
  }
  .ui-dialog-close {
    font-size: 1.2em;
    padding: 0;
    height: auto;
  }

  .ui-dialog-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    padding: 15px;
    gap: 15px;
  }

  .ui-dialog BUTTON {
    font-size: 24px;
    padding: 10px 20px;
    width: 100% !important;
  }

}

</style>