<template>

  <div class="ui-mobile-bottom mobile">
    <slot></slot>
  </div>

</template>

<script>

export default {
  name: 'UiMobileBottom',
}

</script>

<style>

.ui-mobile-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #bdc3c7;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(3px);
  padding: 15px;
}

.ui-mobile-bottom BUTTON {
  font-size: 24px;
  padding: 10px 20px;
  width: 100% !important;
}

</style>
