<template>
  <div>
    <div id="app-update" @click="appReload" v-if="needsUpdate">
      <i class="far fa-arrows-rotate"></i>
      A new app version is available.
      Click here to reload the page.
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      needsUpdate: false,
    };
  },

  mounted() {
    document.addEventListener('updateNeeded', () => {
      this.needsUpdate = true;
    });
  },

  methods: {
    appReload() {
      window.location.reload();
    },
  },
}

</script>

<style>

#app-update {
  position: fixed;
  z-index: 9999;
  bottom: 10px;
  background: #2c3e50;
  color: white;
  font-size: 1.2em;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
#app-update I {
  margin-right: 10px;
}

/*
 * Desktop styles start
 */

@media (min-width: 961px) {

  #app-update {
    left: 50%;
    transform: translateX(-50%);
  }

}

  /*
 * Mobile styles start
 */

@media (max-width: 960px) {

  #app-update {
    left: 10px;
    right: 10px;
  }
}

</style>