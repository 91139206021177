<template>
  <div class="app-login">

    <div class="app-login-container">
      <div class="app-login-top">
        <ui-control
          type="checkbox"
          v-model="confirm"
          description="I confirm removing all my account data."
          :error="error"
        />
        <ui-button
          :loading="loading"
          @click="deleteAccount"
        >
          Delete your account
        </ui-button>
      </div>
    </div>

  </div>
</template>

<script>
import api from '@/lib/api.js';

export default {
  name: 'AppDelete',

  data() {
    return {
      confirm: false,
      error: null,
      loading: false,
    };
  },

  methods: {
    deleteAccount() {
      this.error = null;

      if(!this.confirm) {
        this.error = 'Confirm account deletion';
        return;
      }

      this.loading = true;
      api.post('/user/delete', {
      }).then((response) => {
        this.loading = false;
        if(response.ok) {
          this.$store.commit('logout');
          this.$router.push('/');
        } else {
          this.error = "Unknown error, please try again";
        }
      }).catch((error) => {
        console.error(error);
        this.loading = false;
        this.error = "Unknown error, please try again";
      });
    },
  }
}

</script>

<style>

</style>
