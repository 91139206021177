<template>
  <div class="ui-header">
    <div class="ui-header-inner">
      <!--
      <div>
        <slot name="back">
          <UiHeaderBack v-if="!noBack"/>
        </slot>
      </div>
      -->
      <div class="ui-header-title mobile">
        {{ title }}
      </div>
      <div class="ui-header-logo">
        <slot name="logo">
          <div class="ui-header-logo-image" @click="goHome">
            <img src="../../../assets/logo_horizontal.png"/>
          </div>
        </slot>
      </div>
      <div class="ui-header-actions" :class="{ opened: menuOpen }">
        <div class="ui-header-trigger mobile" @click="toggleMenu">
          <i class="fas fa-bars"></i>
        </div>
        <slot name="actions">
          <div class="ui-header-menu">
            <a class="ui-header-menu-item" href="#" @click.prevent="goHome">How it works</a>
            <a class="ui-header-menu-item" href="#" @click.prevent="goStart">Start an assignment</a>
            <a class="ui-header-menu-item" href="#" @click.prevent="goAssignments">Your assignments</a>
            <a class="ui-header-menu-item" href="#" @click.prevent="goAccount">Your account</a>
          </div>
        </slot>
      </div>
    </div>
  </div>

  <ui-dialog
    :width="400"
    title="Log in to view your account"
    no-buttons
    @close="login = false"
    v-if="login"
  >
    <app-login default-login @login="didLogin"/>
  </ui-dialog>

</template>

<script>

//import UiHeaderBack from './Header/Back.vue';

export default {
  name: 'UiPageHeader',
  components: {
//    UiHeaderBack
  },
  props: {
    title: String,
    noBack: Boolean
  },
  data() {
    return {
      login: false,
      redirect: null,
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },

    goHome() {
      this.$router.push('/');
    },

    goStart() {
      this.$router.push('/choose');
    },

    goAssignments() {
      if(!this.$store.getters.isLogged) {
        this.redirect = '/assignments';
        this.login = true;
      } else {
        this.$router.push('/assignments');
      }
    },

    goAccount() {
      if(!this.$store.getters.isLogged) {
        this.redirect = '/account';
        this.login = true;
      } else {
        this.$router.push('/account');
      }
    },

    didLogin() {
      this.login = false;
      this.$router.push(this.redirect);
    }
  },
  computed: {
    // Your computed properties here
  },
  mounted() {
    // Lifecycle hook
  }
};
</script>

<style>
.ui-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
  padding: 6px 12px;
}

.ui-header-inner {
  margin: 0 auto;
  width: calc(100vw - 60px);
  max-width: 1600px;
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
}

.ui-header-logo-image {
  cursor: pointer;
}
.ui-header-logo IMG {
  height: 40px;
}

/*
 * Desktop styles start
 */

@media (min-width: 1025px) {

  A.ui-header-menu-item {
    text-decoration: none !important;
    color: black;
    padding: 6px 12px;
    font-size: 1.2em;
    border-radius: 999px;
  }

  A.ui-header-menu-item:hover {
    background: #ecf0f1;
  }

}

/*
 * Mobile styles start
 */

@media (max-width: 1024px) {

  .ui-header {
    background: #ecf0f1;
  }

  .ui-header-inner {
    width: calc(100vw - 30px);
  }

  .ui-header-logo {
    display: none;
  }

  .ui-header-title {
    font-size: 1.5em;
    font-weight: 600;
  }

  .ui-header-actions:not(.opened) .ui-header-menu {
    display: none;
  }

  .ui-header-trigger {
    padding: 10px 15px;
    margin-right: -15px;
    font-size: 1.3em;
  }

  .ui-header-menu {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 9998;
  }

  A.ui-header-menu-item {
    display: block;
    text-decoration: none !important;
    color: black;
    padding: 10px 15px;
    font-size: 1.3em;
    border-bottom: 1px solid #bdc3c7;
  }
}

</style>