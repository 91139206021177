<template>
  <ui-page require-login title="Your account">
    <div class="view-account desktop">

      <!-- Desktop links -->

      <div class="links">

        <account-link
          :active="currentLink == 'email'"
          @click="setLink('email')"
          icon="far fa-envelope"
          title="Your email preferences"
        />

        <account-link
          :active="currentLink == 'subscription'"
          @click="setLink('subscription')"
          icon="far fa-credit-card"
          title="Your subscription"
        />

        <account-link
          :active="currentLink == 'security'"
          @click="setLink('security')"
          icon="far fa-lock"
          title="Account security"
        />

        <account-link
          :active="currentLink == 'delete'"
          @click="setLink('delete')"
          icon="far fa-trash-alt"
          title="Delete your account"
        />

        <account-link
          @click="logOut"
          icon="far fa-sign-out-alt"
          title="Log out"
        />

      </div>

      <!-- Desktop panes -->

      <div class="info" v-if="currentLink == 'email'">

        <div class="header">
          Change your email
        </div>

        <p>
          Your email address is <strong>{{ $store.getters.userEmail }}</strong>
        </p>

        <ui-button @click="dialogs.email = true">
          Change your email address
        </ui-button>

        <p v-if="$store.getters.receiveMarketing">
          You are receiving all email messages.
        </p>
        <p v-else>
          You opted out from receiving marketing messages.
        </p>

        <ui-button @click="dialogs.marketing = true">
          Change messaging preferences
        </ui-button>

      </div>

      <div class="info" v-if="currentLink == 'security'">

        <div v-if="$store.getters.hasPassword">

          <div class="header">
            Change your password
          </div>

          <p>
            Click the button to change your account password:
          </p>

          <ui-button @click="dialogs.password = true">
            Change your password
          </ui-button>

        </div>
        
        <div v-else>

          <div class="header">
            Set your password
          </div>

          <p>
            Click the button to create a password for your account:
          </p>

          <ui-button @click="dialogs.password = true">
            Set your password
          </ui-button>

          </div>

      </div>

      <div class="info" v-if="currentLink == 'delete'">

        <div class="header">
          Delete your account
        </div>

        <p>
          If you want to remove your account, click the button below.
        </p>

        <ui-button @click="dialogs.delete = true">
          Delete your account
        </ui-button>

        </div>

      <div class="info" v-if="currentLink == 'subscription'">

        <div v-if="$store.getters.hasSubscription && !$store.getters.subscription.canceled">

          <div class="header">
            Your subscription is active
          </div>

          <p>
            Your <strong>{{ $store.getters.subscription.period }}</strong> payment is
            <strong>{{ $store.getters.subscription.price }} {{ $store.getters.subscription.currency }}</strong>.
          </p>

          <p>
            Your credit card will be billed again on
            <strong><ui-time :timestamp="$store.getters.subscription.nextRebill"></ui-time></strong>.
          </p>

          <ui-button :loading="loading.manage" @click="manage">
            Manage your subscription
          </ui-button>

        </div>

        <div v-if="$store.getters.hasSubscription && $store.getters.subscription.canceled">

          <div class="header">
            Your subscription is canceled
          </div>

          <p>
            Your subscription will end on
            <strong><ui-time :timestamp="$store.getters.subscription.nextRebill"></ui-time></strong>.
          </p>

          <ui-button :loading="loading.manage" @click="manage">
            Reactivate your subscription
          </ui-button>

        </div>

        <div v-if="!$store.getters.hasSubscription">

          <div class="header">
            You don't have an active subscription
          </div>

          <p>
            Click the button below to subscribe to our AI plan:
          </p>

          <ui-button :loading="loading.subscribe" @click="subscribe">
            Start your subscription
          </ui-button>

        </div>

      </div>

    </div>

    <!-- Mobile view items -->

    <div class="view-account mobile">

      <ui-mobile-item
        icon="fal fa-envelope"
        title="Change email address"
        :subtitle="$store.getters.userEmail"
        chevron
        @click="dialogs.email = true"
      />

      <ui-mobile-item
        icon="fal fa-messages"
        title="Change email preferences"
        :subtitle="$store.getters.receiveMarketing ? 'You are receiving all messages' : 'You opted out of marketing messages'"
        chevron
        @click="dialogs.marketing = true"
      />

      <ui-mobile-item
        icon="fal fa-credit-card"
        title="Manage your subscription"
        :subtitle="$store.getters.hasSubscription ? ($store.getters.subscription.canceled ? 'Subscription is canceled' : 'Subscription is active') : 'Subscription is not active'"
        chevron
        @click="if($store.getters.hasSubscription) { manage(); } else { subscribe(); }"
      />

      <ui-mobile-item
        icon="fal fa-lock"
        title="Change your password"
        chevron
        @click="dialogs.password = true"
      />

      <ui-mobile-item
        icon="fal fa-trash-alt"
        title="Delete your account"
        chevron
        @click="dialogs.delete = true"
      />

      <ui-mobile-item
        icon="fal fa-sign-out-alt"
        title="Log out"
        chevron
        @click="logOut"
      />

    </div>

    <ui-mobile-bottom>
      <ui-button confirm @click="start">
        Start a new assignment
      </ui-button>
    </ui-mobile-bottom>

    <!-- Individual setting diaogs -->

    <ui-dialog
      :width="400"
      title="Change your email"
      no-buttons
      @close="dialogs.email = false"
      v-if="dialogs.email"
    >
      <app-email @email="dialogs.email = false"/>
    </ui-dialog>

    <ui-dialog
      :width="400"
      title="Email preferences"
      no-buttons
      @close="dialogs.marketing = false"
      v-if="dialogs.marketing"
    >
      <app-marketing @close="dialogs.marketing = false"/>
    </ui-dialog>

    <ui-dialog
      :width="400"
      title="Delete your account"
      no-buttons
      @close="dialogs.delete = false"
      v-if="dialogs.delete"
    >
      <app-delete/>
    </ui-dialog>

    <ui-dialog
      :width="400"
      title="Change your password"
      no-buttons
      @close="dialogs.password = false"
      v-if="dialogs.password"
    >
      <app-password @password="dialogs.password = false"/>
    </ui-dialog>

    <ui-mobile-loading v-if="loading.subscribe || loading.manage"/>

  </ui-page>
</template>

<script>

import AccountLink from '@/views/Account/Link.vue';

import api from '@/lib/api.js';

export default {
  name: 'AccountView',

  props: {
    initialLink: String,
  },

  components: {
    AccountLink,
  },

  data() {
    return {
      currentLink: this.initialLink ? this.initialLink : this.$store.getters.accountView.link,
      dialogs: {
        password: false,
        email: false,
        delete: false,
        marketing: false,
      },
      loading: {
        password: false,
        subscribe: false,
        manage: false,
        logout: false,
      }
    };
  },

  methods: {
    setLink(link) {
      this.$store.commit('accountViewLink', link);
      this.currentLink = link;
    },

    logOut() {
      this.loading.logout = true;
      api.post('/session/stop', {}
      ).then(() => {
        this.$router.push('/');
      }).catch(() => {
        this.$router.push('/');
      });
    },

    subscribe() {
      this.loading.subscribe = true;
      api.post('/subscription/start', {
        successUrl: window.location.origin + '/#/account/subscription',
        cancelUrl: window.location.origin + '/#/account/subscription',
      }).then((response) => {
        window.location = response.redirect;
      }).catch(() => {
        this.loading.subscribe = false;
      });
    },

    manage() {
      this.loading.manage = true;
      api.post('/subscription/manage', {
        returnUrl: window.location.origin + '/#/account/subscription',
      }).then((response) => {
        window.location = response.redirect;
      }).catch(() => {
        this.loading.manage = false;
      });
    },

    start() {
      this.$router.push('/choose');
    },
  },
}

</script>

<style>

.view-account {
  padding-top: 20px;
  display: flex;
}

.view-account .links {
  width: 25%;
  padding-right: 20px;
}

.view-account .info {
  width: 75%;
  border-left: 1px solid #bdc3c7;
  padding-left: 20px;
  height: calc(100vh - 100px);
}

.view-account .header {
  font-size: 1.2em;
  margin: 10px 0;
}

/*
 * Narrow screen styles start
 */

@media (max-width: 1279px) {

  .view-account .links {
    width: 30%;
  }

  .view-account .info {
    width: 70%;
  }

}

/*
 * Mobile styles start
 */

@media (max-width: 1024px) {

  .view-account {
    padding-top: 10px;
    flex-direction: column;
  }

}

</style>