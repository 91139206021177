import { createStore } from 'vuex'

export default createStore({
  state: {
    initialized: false,
    security: {
      session: window.localStorage.getItem('session') || null,
      isLogged: false,
    },
    user: {
      email: null,
      fullName: null,
      firstName: null,
      lastName: null,
      hasPassword: false,
      receiveMarketing: false,
    },
    subscription: {
      active: false,
      period: null,
      type: null,
      price: null,
      currency: null,
      nextRebill: null,
      canceled: false
    },
    chooseView: {
      level1: 0,
      level2: 0,
    },
    accountView: {
      link: 'email',
    },
    assignments: [],
    assistant: {
      autoOpen: true,
    },
    subscriptionPrices: {
      'monthly': {
        price: 9.95,
        currency: 'USD',
      }
    },
    assignmentNames: {
      'writing': {
        'ielts_academic_1': [
          'Sample assignment 1', 'Sample assignment 2', 'Sample assignment 3', 'Sample assignment 4', 'Sample assignment 5', 'Sample assignment 6', 'Sample assignment 7', 'Sample assignment 8', 'Sample assignment 9', 'Sample assignment 10', 'Sample assignment 11', 'Sample assignment 12', 'Sample assignment 13', 'Sample assignment 14', 'Sample assignment 15', 'Sample assignment 16', 'Sample assignment 17', 'Sample assignment 18', 'Sample assignment 19', 'Sample assignment 20', 'Sample assignment 21', 'Sample assignment 22', 'Sample assignment 23', 'Sample assignment 24', 'Sample assignment 25', 'Sample assignment 26', 'Sample assignment 27', 'Sample assignment 28', 'Sample assignment 29', 'Sample assignment 30', 'Sample assignment 31', 'Sample assignment 32', 'Sample assignment 33', 'Sample assignment 34', 'Sample assignment 35', 'Sample assignment 36', 'Sample assignment 37', 'Sample assignment 38', 'Sample assignment 39', 'Sample assignment 40', 'Sample assignment 41', 'Sample assignment 42', 'Sample assignment 43', 'Sample assignment 44', 'Sample assignment 45', 'Sample assignment 46', 'Sample assignment 47', 'Sample assignment 48', 'Sample assignment 49', 'Sample assignment 50'
        ],
        'ielts_academic_2': [
          'Sample assignment 1', 'Sample assignment 2', 'Sample assignment 3', 'Sample assignment 4', 'Sample assignment 5', 'Sample assignment 6', 'Sample assignment 7', 'Sample assignment 8', 'Sample assignment 9', 'Sample assignment 10', 'Sample assignment 11', 'Sample assignment 12', 'Sample assignment 13', 'Sample assignment 14', 'Sample assignment 15', 'Sample assignment 16', 'Sample assignment 17', 'Sample assignment 18', 'Sample assignment 19', 'Sample assignment 20', 'Sample assignment 21', 'Sample assignment 22', 'Sample assignment 23', 'Sample assignment 24', 'Sample assignment 25', 'Sample assignment 26', 'Sample assignment 27', 'Sample assignment 28', 'Sample assignment 29', 'Sample assignment 30', 'Sample assignment 31', 'Sample assignment 32', 'Sample assignment 33', 'Sample assignment 34', 'Sample assignment 35', 'Sample assignment 36', 'Sample assignment 37', 'Sample assignment 38', 'Sample assignment 39', 'Sample assignment 40', 'Sample assignment 41', 'Sample assignment 42', 'Sample assignment 43', 'Sample assignment 44', 'Sample assignment 45', 'Sample assignment 46', 'Sample assignment 47', 'Sample assignment 48', 'Sample assignment 49', 'Sample assignment 50'
        ],
        'ielts_general_1': [
          'Sample assignment 1', 'Sample assignment 2', 'Sample assignment 3', 'Sample assignment 4', 'Sample assignment 5', 'Sample assignment 6', 'Sample assignment 7', 'Sample assignment 8', 'Sample assignment 9', 'Sample assignment 10', 'Sample assignment 11', 'Sample assignment 12', 'Sample assignment 13', 'Sample assignment 14', 'Sample assignment 15', 'Sample assignment 16', 'Sample assignment 17', 'Sample assignment 18', 'Sample assignment 19', 'Sample assignment 20', 'Sample assignment 21', 'Sample assignment 22', 'Sample assignment 23', 'Sample assignment 24', 'Sample assignment 25', 'Sample assignment 26', 'Sample assignment 27', 'Sample assignment 28', 'Sample assignment 29', 'Sample assignment 30', 'Sample assignment 31', 'Sample assignment 32', 'Sample assignment 33', 'Sample assignment 34', 'Sample assignment 35', 'Sample assignment 36', 'Sample assignment 37', 'Sample assignment 38', 'Sample assignment 39', 'Sample assignment 40', 'Sample assignment 41', 'Sample assignment 42', 'Sample assignment 43', 'Sample assignment 44', 'Sample assignment 45', 'Sample assignment 46', 'Sample assignment 47', 'Sample assignment 48', 'Sample assignment 49', 'Sample assignment 50'
        ],
        'ielts_general_2': [
          'Sample assignment 1', 'Sample assignment 2', 'Sample assignment 3', 'Sample assignment 4', 'Sample assignment 5', 'Sample assignment 6', 'Sample assignment 7', 'Sample assignment 8', 'Sample assignment 9', 'Sample assignment 10', 'Sample assignment 11', 'Sample assignment 12', 'Sample assignment 13', 'Sample assignment 14', 'Sample assignment 15', 'Sample assignment 16', 'Sample assignment 17', 'Sample assignment 18', 'Sample assignment 19', 'Sample assignment 20', 'Sample assignment 21', 'Sample assignment 22', 'Sample assignment 23', 'Sample assignment 24', 'Sample assignment 25', 'Sample assignment 26', 'Sample assignment 27', 'Sample assignment 28', 'Sample assignment 29', 'Sample assignment 30', 'Sample assignment 31', 'Sample assignment 32', 'Sample assignment 33', 'Sample assignment 34', 'Sample assignment 35', 'Sample assignment 36', 'Sample assignment 37', 'Sample assignment 38', 'Sample assignment 39', 'Sample assignment 40', 'Sample assignment 41', 'Sample assignment 42', 'Sample assignment 43', 'Sample assignment 44', 'Sample assignment 45', 'Sample assignment 46', 'Sample assignment 47', 'Sample assignment 48', 'Sample assignment 49', 'Sample assignment 50'
        ],
      }
    }
  },
  getters: {
    initialized: state => state.initialized,
    session: state => state.security.session,
    isLogged: state => state.security.isLogged,
    hasSubscription: state => state.subscription.active,
    subscription: state => state.subscription,
    hasPassword: state => state.user.hasPassword,
    userEmail: state => state.user.email,
    receiveMarketing: state => state.user.receiveMarketing,
    assignments: state => state.assignments,
    chooseView: state => state.chooseView,
    accountView: state => state.accountView,
    assistantAutoOpen: state => state.assistant.autoOpen,
    subscriptionPrice: state => state.subscriptionPrices['monthly'],
    assignmentNames: state => state.assignmentNames,
  },
  mutations: {
    session(state, session) {
      window.localStorage.setItem('session', session);
      state.security.session = session;
    },
    user(state, user) {
      state.security.isLogged = true;
      state.user.email = user.email;
      state.user.fullName = user.fullName;
      state.user.firstName = user.firstName;
      state.user.lastName = user.lastName;
      state.user.hasPassword = user.hasPassword;
      state.user.receiveMarketing = user.receiveMarketing;
      if(user.subscription) {
        state.subscription.active = true;
        state.subscription.period = user.subscription.period;
        state.subscription.type = user.subscription.type;
        state.subscription.price = user.subscription.price;
        state.subscription.currency = user.subscription.currency;
        state.subscription.nextRebill = user.subscription.nextRebill;
        state.subscription.canceled = user.subscription.canceled;
      } else {
        state.subscription.active = false;
      }
    },
    email(state, email) {
      state.user.email = email;
    },
    marketing(state, receiveMarketing) {
      state.user.receiveMarketing = receiveMarketing;
    },
    hasPassword(state) {
      state.user.hasPassword = true;
    },
    logout(state) {
      window.localStorage.removeItem('session');
      state.security.session = null;
      state.security.isLogged = false;
      state.subscription.active = false;
      state.assignments = [];
    },
    initialize(state) {
      state.initialized = true;
    },
    assignments(state, assignments) {
      state.assignments = assignments;
    },
    addAssignment(state, assignment) {
      state.assignments.push(assignment);
    },
    startAssignment(state, id) {
      var assignment = state.assignments.find(assignment => assignment.id === id);
      if(assignment) assignment.started = true;
    },
    finishAssignment(state, { id, grade }) {
      var assignment = state.assignments.find(assignment => assignment.id === id);
      if(assignment) {
        assignment.finished = true;
        assignment.grade = grade;
      }
    },
    chooseViewLevel1(state, level1) {
      state.chooseView.level1 = level1;
    },
    chooseViewLevel2(state, level2) {
      state.chooseView.level2 = level2;
    },
    accountViewLink(state, link) {
      state.accountView.link = link;
    },
    assistantOpened(state) {
      state.assistant.autoOpen = false;
    },
    subscriptionPrices(state, prices) {
      state.subscriptionPrices = prices;
    },
    assignmentNames(state, names) {
      state.assignmentNames = names;
    },
  },
  actions: {
  },
  modules: {
  }
})
