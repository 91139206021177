<template>
  <div class="assignment-assistant">
    <div class="assignment-assistant-trigger" @click="open()">
      <div class="circle1">
        <i class="fad fa-user-shakespeare"></i>
      </div>
    </div>
    <div class="assignment-assistant-overlay" v-if="opened" @click.self="close">
      <div class="assignment-assistant-conversation" :class="{ 'fullscreen': fullScreen }">
        <div class="header">
          <div class="title">AI Assistant</div>
          <div class="icons">
            <div @click="fullScreen = true" v-if="!fullScreen">
              <i class="fas fa-expand"></i>
            </div>
            <div @click="fullScreen = false" v-if="fullScreen">
              <i class="fas fa-compress"></i>
            </div>
            <div @click="close">
              <i class="fas fa-times"></i>
            </div>
          </div>
        </div>
        <div class="utterances">
          <div>
            <div class="utterance utterance-assistant">
              <div>Hello! My name is Shakespeare. You can ask me any question about the assignment, and I'll be happy to help!</div>
            </div>
            <div
              class="utterance"
              :class="{ 'utterance-assistant': message.role === 'assistant', 'utterance-user': message.role === 'user' }"
              v-for="(message, key) in messages"
              :key="key"
            >
              <div>{{ message.content }}</div>
            </div>
            <div class="utterance utterance-assistant utterance-sending" v-if="sending">
              <div>
                <i class="fas fa-spinner fa-spin"></i>
                Generating response...
              </div>
            </div>
            <div class="utterance utterance-assistant utterance-end" v-if="error">
              <div>
                Error sending message. <a href="#" @click.prevent="retryClick">Click here to try again</a>.
              </div>
            </div>
            <div
              class="utterance utterance-example"
              v-for="(example, key) in examples"
              v-if="showExamples"
              :key="key"
              @click="sendMessage(example)"
            >
              <div>{{ example }}</div>
            </div>
          </div>
        </div>
        <div class="input">
          <textarea 
            :style="{ height: `${textareaHeight}px` }"
            ref="textarea"
            placeholder="Type your question here..."
            v-model="message"
            @keyup="resizeTextarea"
            @keypress.enter="sendClick"
            :disabled="sending || error"
          ></textarea>
          <div
            class="send"
            :class="{ 'disabled': sending || error }"
            @click="sendClick"
          >
            <i class="far fa-paper-plane-top"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="assignment-assistant-mobile" v-if="openedMobile" @click="closeMobile">
      <div>
        Hi, I'm your AI assistant! If you need help with your assignment, just ask me a question!
      </div>
      <div class="close" @click="closeMobile">
        <i class="fas fa-times"></i>
      </div>
    </div>
  </div>
</template>

<script>

/* eslint-disable vue/no-use-v-if-with-v-for */

import api from '@/lib/api.js';

const EXAMPLE_MESSAGES = {
  'start': ["I don't know how to start.", "What should I write about?", "What are some good ideas for the first paragraph?"],
  'middle': ["How am I doing so far?", "I'm stuck and need help.", "What should I write next?"],
  'end': ["How can I improve my text?", "What are some good ideas for the conclusion?"],
  'finished': ["How could I do better next time?", "What should I keep in mind for the next assignment?"],
};

export default {
  name: 'AssignmentAssistant',

  props: {
    assignment: Object,
    editor: Object,
    grading: Object,
    initialMessages: Array,
  },

  data() {
    return {
      opened: false,
      openedMobile: false,
      fullScreen: false,
      message: '',
      messages: this.initialMessages,
      textareaHeight: 39,
      showExamples: true,
      sending: false,
      asked: {
        'start': false,
        'middle': false,
        'end': false,
      },
      error: false,
    };
  },


  computed: {
    phase() {
      const words = this.editor.text.trim().split(/\s+/).length;
      if(this.grading.finished) {
        return 'finished';
      } else if(words < 5) {
        return 'start';
      } else if(words < this.assignment.wordCount *0.9) {
        return 'middle';
      } else {
        return 'end';
      }
    },

    examples() {
      const phase = this.phase;
      var examples = EXAMPLE_MESSAGES[phase];
      var ret = [];
      examples.forEach((example) => {
        var was = false;
        this.messages.forEach((message) => {
          if(phase != 'middle' && message.content.toLowerCase().includes(example.toLowerCase())) {
            was = true;
          }
        });
        if(!was) {
          ret.push(example);
        }
      });
      return ret;
    },
  },

  methods: {

    open(auto = false) {
      if(this.opened) {
        return;
      }

      if (auto && window.innerWidth <= 960) {
        this.openedMobile = true;
        return;
      } else {
        this.openedMobile = false;
      }
    
      this.message = '';
      this.textareaHeight = 39;
      this.opened = true;
      if(!this.sending && !this.error) this.showExamples = true;
      this.$emit('open');
      this.$nextTick(() => {
        this.$refs['textarea'].focus();
      });
    },

    close() {
      if(!this.opened) {
        return;
      }
      this.opened = false;
      this.$emit('close');
    },

    closeMobile() {
      this.openedMobile = false;
      this.$store.commit('assistantOpened');
    },

    resizeTextarea() {
      var height = this.$refs.textarea.scrollHeight;
      if(height > 124) {
        height = 124;
      }
      this.textareaHeight = height;
    },

    sendClick() {
      this.sendMessage();
    },

    retryClick() {
      this.sendMessage(this.messages[this.messages.length - 1].content, true);
    },

    sendMessage(text = null, retry = false) {
      if(text === null) {
        text = this.message;
      }
      text = text.trim();
      if(!text.length) {
        return;
      }

      this.error = false;
      this.sending = true;
      this.showExamples = false;
      if(!retry) this.messages.push({ role: 'user', content: text });
      this.message = '';

      api.post(`/assignment/${this.assignment.id}/message`, { 
        content: text,
        response: this.editor.text,
      })
      .then(response => {
        this.messages.push({ role: 'assistant', content: response.content });
        this.sending = false;
        // Must be on the next tick because the textarea is disabled when sending
        this.$nextTick(() => {
          this.$refs['textarea'].focus();
        });
      })
      .catch(() => {
        this.error = true;
        this.sending = false;
      });
    },
  },

  mounted() {
    // lifecycle hook
  }
};
</script>

<style>
.assignment-assistant {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9997;
}

.assignment-assistant-trigger {
  width: 76.5px;
  height: 76.5px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1.5px solid #3498db;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.assignment-assistant-trigger:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.45);
}

.assignment-assistant-trigger .circle1{
  width: 70px;
  height: 70px;
  background: #3498db;
  background: linear-gradient(135deg, #56b0ff, #2980b9);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
}

.assignment-assistant-trigger I {
  color: white;
  font-size: 44px;
  margin-bottom: 3px;
}

.assignment-assistant-overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.assignment-assistant-conversation {
  position: fixed;
  bottom: 96px;
  right: 10px;
  background: white;
  border: 1px solid #bdc3c7;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.assignment-assistant-conversation.fullscreen {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  bottom: 20px;
  right: 20px;
  max-height: 100vh;
}

.assignment-assistant-conversation .header {
  height: 46px;
  background: #ecf0f1;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 14px;
  border-bottom: 1px solid #bdc3c7;
  flex-shrink: 0;
}

.assignment-assistant-conversation .title {
  font-size: 1.2em;
  font-weight: 600;
}
.assignment-assistant-conversation .icons {
  font-size: 1.2em;
  display: flex;
  gap: 3px;
}
.assignment-assistant-conversation .icons > DIV {
  cursor: pointer;
  border-radius: 999px;
  width: 32px;
  height: 32px;
  padding-top: 3px;
  text-align: center;
}
.assignment-assistant-conversation .icons > DIV:hover {
  background: #bdc3c7;
}

.assignment-assistant-conversation .utterances {
  flex-grow: 999;
  overflow-y: auto;
  padding: 8px 8px 0 8px;
  overflow:auto; display:flex; flex-direction:column-reverse;
}

.assignment-assistant-conversation .input {
  display: flex;
  border-top: 1px solid #bdc3c7;
}

.assignment-assistant-conversation TEXTAREA {
  width: calc(100% - 40px);
  border: none;
  outline: none;
  padding: 7px 10px;
  resize: none;
}

.assignment-assistant-conversation .send {
  background: #3498db;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  cursor: pointer;
}
.assignment-assistant-conversation .send:not(.disabled):hover {
  background: #2790d3;
}
.assignment-assistant-conversation .send.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.assignment-assistant-conversation .utterance > DIV {
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 8px;
  white-space: pre-line;
  hyphens: auto;
}
.assignment-assistant-conversation .utterance::after {
  content: "";
  clear: both;
  display: table;
}
.assignment-assistant-conversation .utterance-assistant,
.assignment-assistant-conversation .utterance-example {
    margin-right: 30px;
}
.assignment-assistant-conversation .utterance-assistant > DIV {
  background: #fffbe5;
  float: left;
}
.assignment-assistant-conversation .utterance-example > DIV {
  background: white;
  border: 1px solid #3498db;
  color: #3498db;
  cursor: pointer;
  float: left;
}
.assignment-assistant-conversation .utterance-user {
  margin-left: 30px;
}
.assignment-assistant-conversation .utterance-user > DIV {
  background: #ecf0f1;
  float: right;
}
.assignment-assistant-conversation .utterance-sending > DIV {
  color: #95a5a6;
}

/*
 * Mobile styles start
 */

@media (min-width: 961px) {

  .assignment-assistant-conversation {
    width: 500px;
    height: calc(100vh - 145px);
    max-height: 700px;
  }

}

/*
 * Mobile styles start
 */

@media (max-width: 960px) {

  .assignment-assistant-trigger {
    position: fixed;
    bottom: 2px;
    right: 2px;
  }

  .assignment-assistant-conversation {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
    z-index: 9997;
  }

  .assignment-assistant-conversation .header {
    height: 60px;
    font-size: 1.2em;
  }
  .assignment-assistant-conversation .icons {
    font-size: 1.2em;
  }
  .assignment-assistant-conversation .icons > DIV {
    height: auto;
  }
  .assignment-assistant-conversation .icons > DIV:first-child {
    display: none;
  }

  .assignment-assistant-mobile {
    position: fixed;
    bottom: 90px;
    right: 10px;
    width: calc(100vw - 80px);
    text-align: right;
    background: #fffbe5;
    border: 1px solid #bdc3c7;
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  }

  .assignment-assistant-mobile .close {
    position: absolute;
    top: -15px;
    left: -15px;
    background: white;
    border: 1px solid #95a5a6;
    font-size: 1.2em;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    cursor: pointer;
  }

}


</style>