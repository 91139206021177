<template>
  <div class="assignment-timer">
    <span class="assignment-timer-time" :class="{ 'assignment-timer-expired': expired }" v-show="showTime">
      {{ minutes }}:{{ seconds }}
    </span>
    <ui-button small @click="pause" :width="90" :disabled="expired" v-if="!paused">
      <i class="fas fa-pause"></i> Pause
    </ui-button>
    <ui-button small @click="resume" :width="90" :disabled="expired" v-else>
      <i class="fas fa-play"></i> Resume
    </ui-button>
  </div>
</template>

<script>
export default {
  name: 'AssignmentTimer',
  
  data() {
    return {
      currentTime: 0,
      startTime: 0,
      additionalTime: 0,
      lastStarted: 0,
      timer: null,
      paused: false,
      maybePaused: false,
      expired: false,
      flashInterval: 0,
    };
  },

  computed: {
    minutes() {
      const minutes = Math.floor(this.currentTime / 60);
      if(minutes < 10) {
        return '0' + minutes;
      } else {
        return minutes;
      }
    },
    seconds() {
      const seconds = this.currentTime % 60;
      if(seconds < 10) {
        return '0' + seconds;
      } else {
        return seconds;
      }
    },
    showTime() {
      if((!this.paused && !this.expired) || this.maybePaused) {
        return true;
      } else {
        return (this.flashInterval % 10) >= 5;
      }
    }
  },

  methods: {
    start(time) {
      this.additionalTime = 0;
      this.startTime = time * 1000;
      this.lastStarted = Date.now();
      this.currentTime = 0;
      this.expired = false;
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.updateTimer();
        }, 100);
      }
    },

    updateTimer() {
      if(this.paused || this.expired) {
        this.flashInterval = this.flashInterval + 1;
        return;
      }
      const current = Date.now() - this.lastStarted + this.additionalTime;
      this.currentTime = Math.ceil((this.startTime - current) / 1000);
      if(this.currentTime <= 0 && !this.expired) {
        this.currentTime = 0;
        this.expired = true;
        this.flashInterval = 0;
        this.$emit('expire');
      }
    },

    stop() {
      clearInterval(this.timer);
      this.timer = null;
    },

    pause() {
      if(this.paused) return;
      const diff = Date.now() - this.lastStarted;
      this.additionalTime += diff;
      this.flashInterval = 0;
      this.paused = true;
    },

    maybePause() {
      if(this.paused) return;
      this.maybePaused = true;
      this.pause();
    },

    maybeResume() {
      if(!this.maybePaused) return;
      this.maybePaused = false;
      this.resume();
    },

    resume() {
      if(!this.paused) return;
      this.lastStarted = Date.now();
      this.paused = false;
    },

    isPaused() {
      return this.paused;
    },

    remainingTime() {
      return this.currentTime;
    },
  },

  beforeUnmount() {
    if(this.timer)
      clearInterval(this.timer);
  }
};
</script>

<style>
.assignment-timer-time {
  margin-right: 6px;
  font-weight: 600;
}
.assignment-timer-expired {
  color: #c0392b;
}
</style>