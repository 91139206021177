<template>
  <ui-page title="Your assignment" full-width>
    <div class="view-assignment" :class="{ right: showRight }">

      <div class="tabs" @click="assistantCloseMobile()">

        <div class="tab tab-left" @click="switchMobile(false)">
          Task details
        </div>
        <div class="tab tab-right" @click="switchMobile(true)">
          Your response
        </div>

      </div>

      <div class="canvas" :class="{obfuscated: obfuscated}" @click="assistantCloseMobile()">

        <AssignmentLoading v-if="loading"/>
        <AssignmentError @retry="load" v-if="error"/>
        <AssignmentGrading v-if="finishing"/>

        <!-- Canvas parts shown when editing text -->

        <div class="canvas-left" v-if="!loading && !error && !finishing && !grading.finished">
          <div class="assignment" lang="en">
            <div class="assignment-header">
              <strong>{{ assignment.name }}</strong>
            </div>
            <div class="assignment-text">
              <div v-html="assignmentText"></div>
              <div class="assignment-graph" v-if="assignment.graph" @click="$refs['image'].show()">
                <img :src="graphUrl" />
                <div>
                  <i class="far fa-arrows-maximize"></i>
                  Click to enlarge
                </div>
              </div>
            </div>
            <div class="canvas-buttons">
              <ui-button @click="chooseAnother">Choose another assignment</ui-button>
            </div>
          </div>
        </div>
        <div class="canvas-right" v-if="!loading && !error && !finishing && !grading.finished">
          <div class="canvas-timer" v-show="editor.started">
            <AssignmentWords :text="editor.text"/>
            <AssignmentTimer ref="timer" @expire="timerExpired" v-if="editor.timerStart"/>
          </div>
          <textarea 
            v-model="editor.text"
            :placeholder="textareaPlaceholder"
            ref="textarea"
            spellcheck="false"
          ></textarea>
          <div class="canvas-buttons">
            <ui-button confirm @click="startFinish">Finish this assignment</ui-button>
          </div>
          <AssignmentOverlay :time="assignment.time" v-if="!editor.started" @start="startWriting"/>
        </div>

        <!-- Canvas parts shown when displaying grading -->

        <div class="canvas-left" v-if="!loading && !error && !finishing && grading.finished">
          <div class="assignment" lang="en">
            <div class="assignment-header">
              <strong>Score: <span class="obfuscate">{{ obfuscate(grading.score) }}</span></strong>
              <span class="obfuscate">&nbsp;(out of {{ grading.maxScore }})</span>
            </div>
            <div class="assignment-text">
              <p class="obfuscate">
                {{ obfuscate(grading.scoreDetails.overallScore.description) }}
              </p>
              <template v-for="(criteria, key) in grading.scoreDetails.detailedCriteria" :key="key">
                <p>
                  <strong>{{ criteria.name }}: <span class="obfuscate">{{ obfuscate(criteria.value) }}</span></strong>
                </p>
                <p class="obfuscate">
                  {{ obfuscate(criteria.description) }}
                </p>
              </template>
            </div>
            <div class="canvas-buttons">
              <ui-button @click="chooseAnother">Choose another assignment</ui-button>
            </div>
          </div>
        </div>
        <div class="canvas-right" v-if="!loading && !error && !finishing && grading.finished && !obfuscated">
          <div class="canvas-timer">
            <AssignmentWords :text="editor.text"/>
            <div class="desktop">
              Hover over any correction to see the explanation.
            </div>
          </div>
          <div
            class="assignment-correction"
            lang="en"
            ref="correction"
            v-html="grading.text.replaceAll('del>', 'del>\n').replaceAll(' title=', ' data-title=')"
          >
          </div>
          <div class="canvas-buttons">
            <ui-button confirm @click="restartAssignment">Try this assignment again</ui-button>
          </div>
          <div
            class="assignment-tooltip"
            :style="{ 'top' : tooltipPosition }"
            v-if="tooltip.visible"
          >
            <div class="inner">
              {{ tooltip.text }}
            </div>
            <div><i class="far fa-arrow-right"></i></div>
          </div>
        </div>
        <div class="canvas-right" v-if="!loading && !error && !finishing && grading.finished && obfuscated">
          <AssignmentSubscribe :id="id"/>
        </div>

      </div>

      <!-- Buttons at the bottom of mobile screen -->

      <ui-mobile-bottom v-if="!loading && !error && !finishing" @click="assistantCloseMobile()">
        <ui-button confirm v-if="!editor.started" @click="startWriting({start: true, time: assignment.time})">
          Start writing
        </ui-button>
        <ui-button v-else-if="!grading.finished" @click="startFinish">
          Finish writing
        </ui-button>
        <ui-button v-else @click="chooseAnother">
          Next assignment
        </ui-button>
      </ui-mobile-bottom>

    </div>

    <AssignmentImage
      ref="image"
      :image="assignment.graph"
      v-if="assignment && assignment.graph"
    />

    <AssignmentAssistant
      :assignment="assignment"
      :editor="editor"
      :grading="grading"
      :initialMessages="editor.messages"
      ref="assistant"
      @open="assistantOpened"
      @close="assistantClosed"
      v-if="!loading && !error"
    />

    <ui-dialog
      :width="490"
      title="Finish assignment"
      closeButton="No, continue writing"
      confirmButton="Yes, finish assignment"
      :loading="dialogs.finishPending"
      :no-close="dialogs.finishPending"
      @close="cancelFinish"
      @confirm="doFinish"
      v-if="dialogs.finish"
    >
    <div v-if="wordCount < assignment.wordCount">
      <ui-message-warning>
        You have only written <strong>{{ wordCount }}</strong> out of required <strong>{{ assignment.wordCount }}</strong> words.
        You will receive a lower grade if you finish now.
      </ui-message-warning>
      <p>
        Do you really want to finish your assignment now?
      </p>
    </div>
    <div v-else>
      <p>
        Do you want to finish your assignment now?
      </p>
      <p>
        When you finish, your text will be checked by our AI system.
        You will receive a grade and feedback on your writing.
      </p>
    </div>
    </ui-dialog>

    <ui-dialog
      :width="400"
      title="Sign up to start your assignment"
      no-buttons
      @close="dialogs.login = false"
      v-if="dialogs.login"
    >
      <app-login @login="didLogin"/>
    </ui-dialog>

    <ui-dialog
      :width="400"
      title="Assignment limit reached"
      no-buttons
      @close="dialogs.subscribe = false"
      v-if="dialogs.subscribe"
    >
      <AppSubscribe/>
    </ui-dialog>

</ui-page>
</template>

<script>

import AssignmentAssistant from '@/views/Assignment/Assistant.vue';
import AssignmentError from '@/views/Assignment/Error.vue';
import AssignmentGrading from '@/views/Assignment/Grading.vue';
import AssignmentImage from '@/views/Assignment/Image.vue';
import AssignmentLoading from '@/views/Assignment/Loading.vue';
import AssignmentOverlay from '@/views/Assignment/Overlay.vue';
import AssignmentSubscribe from '@/views/Assignment/Subscribe.vue';
import AssignmentTimer from '@/views/Assignment/Timer.vue';
import AssignmentWords from '@/views/Assignment/Words.vue';

import '@/assets/paper_fibers.png';
import '@/assets/paper_fibers_@2X.png';

import api from '@/lib/api.js';

export default {
  name: 'AssignmentView',
  props: {
    id: String
  },
  components: {
    AssignmentAssistant,
    AssignmentError,
    AssignmentGrading,
    AssignmentImage,
    AssignmentLoading,
    AssignmentOverlay,
    AssignmentSubscribe,
    AssignmentTimer,
    AssignmentWords,
  },
  data() {
    return {
      assignment: null,
      editor: {
        timerStart: true,
        timerPaused: false,
        started: false,
        text: "",
        messages: [],
      },
      grading: {
        finished: false,
        correctedResponse: '',
        score: 0,
        scoreDetails: {},
      },
      dialogs: {
        finish: false,
        login: false,
        subscribe: false,
        startEvent: null,
      },
      tooltip: {
        visible: false,
        y: 0,
        text: "",
      },
      loading: true,
      error: false,
      finishing: false,
      saveInterval: null,
      showRight: false,
    };
  },

  mounted() {
    this.load();
    this.saveInterval = window.setInterval(() => {
      if(this.editor.started) {
        this.save();
      }
    }, 10000);
  },

  beforeUnmount() {
    window.clearInterval(this.saveInterval);
  },

  computed: {
    assignmentText() {
      const paras = this.assignment.description.split('\n\n');
      return '<p>' + paras.join('</p><p>').replaceAll('\n', '<br/>') + '</p>';
    },

    graphUrl() {
      if(this.assignment && this.assignment.graph) {
        return api.url() + this.assignment.graph.url;
      } else {
        return '';
      }
    },

    textareaPlaceholder() {
      return this.editor.started ? "Start writing here..." : "";
    },

    wordCount() {
      if(this.editor.text.trim().length === 0) {
        return 0;
      }
      return this.editor.text.trim().split(/\s+/).length;
    },

    tooltipPosition() {
      return (this.tooltip.y + 50) + 'px';
    },

    obfuscated() {
      return this.grading.finished && !this.$store.getters.hasSubscription;
    },
  },

  methods: {
    load() {
      this.loading = true;
      this.error = false;
      api.get(`/assignment/${this.id}/get`).then((data) => {
        this.assignment = data.assignment;
        this.grading = data.grading;
        this.loading = false;
        this.editor.messages = data.response.messages;
        if(data.response.started) {
          this.editor.started = true;
          this.editor.text = data.response.text;
          if(!data.grading.finished) {
            this.showRight = true;
            this.$nextTick(() => {
              this.$refs.textarea.focus();
              this.$refs.timer.start(data.response.timerRemaining);
              // TODO: Pause timer it not data.response.timerRunning
            });
          }
        } else if(!data.response.messages.length) {
          this.editor.started = false;
          this.grading.finished = false;
          this.editor.text = '';
          // Open assistant after 2 seconds, to show the user how to use it
          if(this.$store.getters.assistantAutoOpen) {
            window.setTimeout(() => {
              if(!this.dialogs.login && !this.dialogs.subscribe) {
                this.$refs.assistant.open(true);
              }
            }, 2000);
          }
        }
        this.$forceUpdate();
      }).catch((error) => {
        console.error(error);
        this.loading = false;
        this.error = true;
      });
    },

    save(time = null) {
      var paused = true;
      if(this.finishing || !this.editor.started || this.grading.finished) {
        return;
      }
      if(!time) {
        if(this.$refs.timer) {
          time = this.$refs.timer.remainingTime();
          paused = this.$refs.timer.isPaused();
        } else {
          time = this.assignment.time;
        }
      }
      api.post(`/assignment/${this.id}/save`, {
        timerRunning: !paused,
        timerRemaining: time,
        response: this.editor.text,
      }).catch(() => {
        console.error("Failed to save assignment response");
      });
    },

    startWriting(event) {
      this.dialogs.startEvent = event;
      if(!this.$store.getters.isLogged) {
        this.dialogs.login = true;
      } else {
        const finishedAssignments = this.$store.getters.assignments.filter(assignment => assignment.finished).length;
        const startedAssignments = this.$store.getters.assignments.filter(assignment => assignment.started).length;
        if((finishedAssignments >= 1 || startedAssignments >= 3) && !this.$store.getters.hasSubscription) {
          this.dialogs.subscribe = true;
        } else {
          this.editor.started = true;
          this.editor.timerStart = event.start;
          this.$refs.timer.start(event.time * 60);
          this.$refs.textarea.focus();
          this.$store.commit('startAssignment', this.id);
          this.save(event.time * 60);
          this.showRight = true;
        }
      }
    },

    didLogin() {
      this.dialogs.login = false;
      this.startWriting(this.dialogs.startEvent);
    },

    timerExpired() {
    },

    assistantOpened() {
      if(!this.editor.started || this.grading.finished) {
        return;
      }
      this.$refs.timer.maybePause();
    },

    assistantClosed() {
      this.$store.commit('assistantOpened');
      if(!this.editor.started || this.grading.finished) {
        return;
      }
      this.$refs.timer.maybeResume();
      this.$refs.textarea.focus();
    },

    assistantCloseMobile() {
      if(this.$refs.assistant) {
        this.$refs.assistant.closeMobile();
      }
    },

    startFinish() {
      this.$refs.timer.maybePause();
      this.finishing = false;
      this.dialogs.finish = true;
    },

    cancelFinish() {
      this.dialogs.finish = false;
      this.$refs.timer.maybeResume();
    },

    doFinish() {
      this.dialogs.finish = false;
      this.finishing = true;
      api.post(`/assignment/${this.id}/finish`, {
        response: this.editor.text,
      }).then((response) => {
        this.grading = response;
        this.finishing = false;
        this.showRight = true;
        this.$store.commit('finishAssignment', { id: this.id, grade: response.score });
      }).catch(() => {
        this.finishing = false;
        console.error("Failed to finish");
      });
    },

    spanOver(span) {
      const rect = span.getBoundingClientRect();
      const parentRect = this.$refs.correction.getBoundingClientRect();
      const y = rect.top - parentRect.top;
      this.tooltip.y = y;
      this.tooltip.visible = true;
      this.tooltip.text = span.getAttribute('data-title');
    },

    spanOut() {
      this.tooltip.visible = false;
    },

    chooseAnother() {
      this.$router.push('/choose');
    },

    restartAssignment() {
      api.post('/assignment/create', {
        id: this.assignment.assignmentId,
        type: this.assignment.type,
        category: this.assignment.category,
      }).then((response) => {
        this.$store.commit('addAssignment', {
          id: response.assignment.id,
          assignmentId: this.assignment.assignmentId,
          type: this.assignment.type,
          category: this.assignment.category,
          started: false,
          finished: false,
          grade: null,
          time: Math.floor(Date.now() / 1000),
        });
        this.$router.replace('/empty');
        window.setTimeout(() => {
          this.$router.replace(`/assignment/${response.assignment.id}`);
        }, 10);
      }).catch(() => {
        console.error("Failed to start");
      });
    },

    switchMobile(right) {
      this.showRight = right;
    },

    obfuscate(text) {
      if(!this.obfuscated) {
        return text;
      } else {
        return text.toString().replace(/\S/g, () => String.fromCharCode(33 + Math.random() * 94));
      }
    }

  },

  watch: {
    grading: {
      handler(newVal) {
        if (newVal.finished) {
          this.$nextTick(() => {
            if (this.$refs.correction) {
              const spans = this.$refs.correction.querySelectorAll('span');
              spans.forEach(span => {
                span.onmouseover = () => {
                  this.spanOver(span);
                };
                span.onmouseout = () => {
                  this.spanOut(span);
                };
              });
            }
          });
        }
      },
      deep: true
    }
  }
}
</script>

<style>
.view-assignment {
  background-image: url('~@/assets/paper_fibers.png');
  padding: 30px;
  height: calc(100vh - 60px);
  margin: 0 -20px;
}

@media only screen and (min-device-pixel-ratio: 2) {
  .view-assignment {
    background-image: url('~@/assets/paper_fibers_@2X.png');
  }
}

.view-assignment .canvas {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  background: white;
  padding: 0px;
  border: 1px solid #bdc3c7;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  text-align: left;
}

.view-assignment .canvas-left {
  width: 50%;
  padding: 20px;
  display: flex;
}

.view-assignment .canvas-buttons {
  margin-top: 10px;
}

.view-assignment .assignment {
  display: flex;
  flex-direction: column;
}

.view-assignment .assignment-header {
  font-size: 1.2em;
}
.view-assignment .assignment-text {
  flex-grow: 999;
  text-align: justify;
  hyphens: auto;
  overflow-y: auto;
  margin-right: -10px;
  padding-right: 10px;
}

.view-assignment .assignment-text P {
  margin: 10px 0;
}
.view-assignment .assignment-text IMG {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.view-assignment .canvas-right {
  width: 50%;
  padding: 32px 20px;
  border-left: 3px dashed #95a5a6;
  margin: -12px 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.view-assignment .canvas-right .canvas-timer {
  height: 26px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.view-assignment .canvas-right .canvas-timer > DIV {
  align-items: center;
}
.view-assignment .canvas-right TEXTAREA {
  font-family: Lora, serif;
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  padding: 0;
  flex-grow: 999;
  line-height: 1.4em;
  /*
  font-style: italic;
  */
}

.view-assignment .assignment-correction {
  flex-grow: 999;
  overflow-y: auto;
  font-family: Lora, serif;
  margin-right: -10px;
}
.view-assignment .assignment-correction SPAN {
  background-color: antiquewhite;
  padding-left: 3px;
  padding-right: 3px;
  cursor: help;
  position: relative;
}
/*
.view-assignment .assignment-correction SPAN:before {
  content: attr(title);
  position:absolute;
  z-index: 999;
  
  top:50%;
  transform:translateY(-50%);
  
  right:100%;
  margin-left:15px;
  
  width:200px;
  padding:10px;
  border-radius:10px;
  background:#000;
  color: #fff;
  text-align:center;

  display:none;
}
.view-assignment .assignment-correction SPAN:before {
  display:block;
}
*/
.view-assignment .assignment-correction DEL {
  color: #e74c3c;
}
.view-assignment .assignment-correction INS {
  color: #27ae60;
}
.view-assignment .assignment-correction P {
  margin: 10px 0;
  text-align: justify;
  hyphens: auto;
  padding-right: 10px;
}
.view-assignment .assignment-correction P:first-child {
  margin-top: 2px;
}

.view-assignment .assignment-tooltip {
  position: absolute;
  right: calc(100% - 16px);
  width: 40vw;
  max-width: 400px;
  z-index: 100;
  display: flex;
}
.view-assignment .assignment-tooltip .inner {
  background: #2c3e50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
.view-assignment .assignment-tooltip I {
  color: #34495e;
  font-size: 1.75em;
  margin: 4px 0 0 4px;
}

.view-assignment .image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.view-assignment .obfuscated .obfuscate {
  filter: blur(5px);
}

/*
 * Desktop styles start
 */

@media (min-width: 961px) {

  .view-assignment .tabs {
    display: none;
  }

}

@media (min-width: 561px) {

  .view-assignment .assignment-graph > DIV {
    display: none;
  }

}

/*
 * Mobile styles start
 */

@media (max-width: 960px) {

  .view-assignment {
    padding: 0;
    margin: 0 -15px;
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: auto;
  }

  .view-assignment .canvas {
    border: none;
    box-shadow: none;
    height: calc(100% - 54px);
    padding-bottom: 62px;
  }

  .view-assignment:not(.right) .canvas-right {
    display: none;
  }
  .view-assignment.right .canvas-left {
    display: none;
  }

  .view-assignment .canvas-left,
  .view-assignment .canvas-right {
    width: 100%;
  }
  .view-assignment .canvas-right {
    border: none;
  }

  .view-assignment .canvas-buttons {
    display: none;
  }

  .view-assignment .tabs {
    display: flex;
    width: 100%;
    padding: 10px 10px 0 10px;
    gap: 10px;
    border-bottom: 1px solid #bdc3c7;
    height: 54px;
  }
  .view-assignment .tab {
    width: 50%;
    padding: 8px 0;
    text-align: center;
    background: white;
    font-size: 1.2em;
    border-top: 1px solid #bdc3c7;
    border-left: 1px solid #bdc3c7;
    border-right: 1px solid #bdc3c7;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .view-assignment:not(.right) .tab-right,
  .view-assignment.right .tab-left {
    background: #f6fafb;
  }
  .view-assignment:not(.right) .tab-left,
  .view-assignment.right .tab-right {
    margin-bottom: -1.1px;
    padding-bottom: 9.1px;
  }

  .view-assignment .ui-mobile-bottom {
    padding-right: 90px;
  }

}

@media (max-width: 560px) {

  .view-assignment .assignment-graph  {
    position: relative;
  }

  .view-assignment .assignment-graph > DIV {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 10px 10px;
    border-radius: 999px;
    border: 1px solid #bdc3c7;
    width: 180px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }

}

</style>
