import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import vue3GoogleLogin from 'vue3-google-login'

import UiButton from './components/ui/Button.vue'
import UiCheckbox from './components/ui/Checkbox.vue'
import UiDialog from './components/ui/Dialog.vue'
import UiEdit from './components/ui/Edit.vue'
import UiMessageWarning from './components/ui/Message/Warning.vue'
import UiPage from './components/ui/Page.vue'
import UiTime from './components/ui/Time.vue'
import UiControl from './components/ui/Control.vue'

import UiMobileBottom from './components/ui/Mobile/Bottom.vue'
import UiMobileItem from './components/ui/Mobile/Item.vue'
import UiMobileLoading from './components/ui/Mobile/Loading.vue'

import AppLogin from './components/app/Login.vue'
import AppPassword from './components/app/Password.vue'
import AppSubscribe from './components/app/Subscribe.vue'
import AppEmail from './components/app/Email.vue'
import AppUpdate from './components/app/Update.vue'
import AppMarketing from './components/app/Marketing.vue'
import AppDelete from './components/app/Delete.vue'

const app = createApp(App)
app.component('UiButton', UiButton)
app.component('UiCheckbox', UiCheckbox)
app.component('UiDialog', UiDialog)
app.component('UiEdit', UiEdit)
app.component('UiMessageWarning', UiMessageWarning)
app.component('UiPage', UiPage)
app.component('UiTime', UiTime)
app.component('UiControl', UiControl)
app.component('UiMobileBottom', UiMobileBottom)
app.component('UiMobileItem', UiMobileItem)
app.component('UiMobileLoading', UiMobileLoading)
app.component('AppLogin', AppLogin)
app.component('AppSubscribe', AppSubscribe)
app.component('AppPassword', AppPassword)
app.component('AppEmail', AppEmail)
app.component('AppUpdate', AppUpdate)
app.component('AppMarketing', AppMarketing)
app.component('AppDelete', AppDelete)
app.use(store)
app.use(router)
app.use(vue3GoogleLogin, {
    clientId: '15193050441-iqkd9cja61pjomncjjh9tq6i3vkdbqim.apps.googleusercontent.com'
})
app.mount('#app')

