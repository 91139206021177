<template>
  <ui-page no-back require-login title="Activate your subscription">
    <div class="view-confirm">
      <div class="icon">
        <i class="far fa-spinner fa-spin"></i>
      </div>
    </div>
  </ui-page>
</template>

<script>

import api from '@/lib/api.js';

export default {
  name: 'ViewConfirm',

  data() {
    return {
      loginInterval: null,
    };
  },

  mounted() {
    if(!this.$store.getters.initialized) {
      // TODO: ugly hack, should be done using watchers or events
      this.loginInterval = setInterval(() => {
        if(this.$store.getters.initialized) {
          clearInterval(this.loginInterval);
          this.redirect();
        }
      }, 50);
    } else {
      this.redirect();
    }
  },

  methods: {
    redirect() {
      if(this.$store.getters.hasSubscription) {
        this.$router.push('/choose');
      } else {
        api.post('/subscription/start', {
          successUrl: window.location.origin + '/#/choose',
          cancelUrl: window.location.origin + '/#/account',
        }).then((response) => {
          window.location = response.redirect;
        }).catch((error) => {
          this.loading = false;
          console.log('Error', error);
        });
      }
    }
  },
}

</script>

<style>
.view-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  width: 100%;
  flex-direction: column;
  color: #bdc3c7;
  font-size: 96px;
}
</style>