<template>
  <div class="assignment-loading">
    <div class="icon">
      <i class="far fa-spinner fa-spin"></i>
    </div>
    <div class="text">
      Loading assignment...
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssignmentLoading',
}
</script>

<style>
.assignment-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  color: #bdc3c7;
}

.assignment-loading .fa-spinner {
  display: block;
  font-size: 96px;
}

.assignment-loading .text {
  margin-top: 16px;
  font-size: 1.5rem;
}
</style>
