<template>
  <div class="assignment-subscribe">
    <div class="header">
      Subscribe to view your grade
    </div>
    <div class="body">
      <div class="item">
        <i class="fas fa-check"></i>
        <span>Over 200+ IELTS Writing assignments.</span>
      </div>
      <div class="item">
        <i class="fas fa-check"></i>
        <span>Assignments graded like on a real exam.</span>
      </div>
      <div class="item">
        <i class="fas fa-check"></i>
        <span>Comprehensive feedback on every task.</span>
      </div>
      <div class="item">
        <i class="fas fa-check"></i>
        <span>AI assistant to help you with writing.</span>
      </div>
      <div class="item">
        <i class="fas fa-check"></i>
        <span>Unlimited attempts for every assignment.</span>
      </div>
    </div>
    <div class="button">
      <div class="icons">
        <img src="@/assets/payment/visa.png"/>
        <img src="@/assets/payment/mastercard.png"/>
        <img src="@/assets/payment/google_pay.png"/>
        <img src="@/assets/payment/apple_pay.png"/>
        <img src="@/assets/payment/paypal.png"/>
      </div>
      <ui-button big confirm @click="subscribe" :loading="loading">
        Subscribe 
        <span class="desktop">for {{ $store.getters.subscriptionPrice.price }} {{ $store.getters.subscriptionPrice.currency }} / month</span>      
      </ui-button>
      <div>You can cancel any time</div>
    </div>
  </div>
</template>

<script>
import api from '@/lib/api.js';

export default {
  name: 'AssignmentOverlay',

  props: {
    id: String,
  },
  
  data() {
    return {
      loading: false
    };
  },

  methods: {
    subscribe() {
      this.loading = true;
      api.post('/subscription/start', {
        successUrl: window.location.origin + '/#/assignment/' + this.id,
        cancelUrl: window.location.origin + '/#/assignment/' + this.id,
      }).then((response) => {
        window.location = response.redirect;
      }).catch((error) => {
        this.loading = false;
        console.log('Error', error);
      });
    },

  }
};
</script>

<style>
.assignment-subscribe {
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  bottom: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: white;
}

.assignment-subscribe .header {
  font-size: 1.5em;
  font-weight: 600;
}

.assignment-subscribe .body {
  margin: 20px 0;
  line-height: 1.6em;
}
.assignment-subscribe .body I {
  color: #27ae60;
  margin-right: 5px;
}

.assignment-subscribe .button {
  text-align: center;
  color: #7f8c8d;
}
.assignment-subscribe .button .icons {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.assignment-subscribe .button .icons IMG {
  width: 48px;
  height: auto;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
}

</style>