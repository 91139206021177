<template>
  <ui-page>
    <div class="view-empty">
    </div>
  </ui-page>
</template>

<script>

export default {
  name: 'ViewEmpty',
}

</script>

<style>
</style>