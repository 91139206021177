<template>
  <ui-page title="Your assignments">
    <div class="view-assignments desktop">

      <div class="list" v-if="assignmentList.length">
        <div class="item"
          v-for="(item, index) in assignmentList"
          @click="showAssignment(item.id)"
          :key="index"
        >
          <div class="item-icon">
            <i v-if="item.finished" class="fal fa-check"></i>
            <i v-else class="fal fa-timer"></i>
          </div>
          <div class="item-header">
            <div class="item-header-title ellipsis">
              {{ item.title }}
            </div>
            <div class="item-header-subtitle ellipsis">
              <span>{{ item.name }}</span>
            </div>
          </div>
          <div class="item-status">
            <span v-if="item.finished">Finished, grade: <strong>{{ item.grade }}</strong></span>
            <span v-else>In progress</span>            
          </div>
          <!--
          <div class="item-time">
            <ui-time :timestamp="item.time" format="relative"></ui-time>
          </div>
          -->
          <div class="item-buttons">
            <ui-button v-if="item.finished" @click="showAssignment(item.id)">
              Show assignment score
            </ui-button>
            <ui-button v-else @click="showAssignment(item.id)">
              Continue assignment
            </ui-button>
          </div>
        </div>
      </div>

      <div v-else class="info">
        <p>You have no assignments yet.</p>
        <ui-button
          big
          @click="$router.push('/choose')"
        >
          Start your first assignment
        </ui-button>
      </div>

  </div>

  <div class="view-assignments mobile">

    <div class="list" v-if="assignmentList.length">
      <ui-mobile-item
        v-for="(item, index) in assignmentList"
        @click="showAssignment(item.id)"
        :key="index"
        :title="item.title"
        :icon="true"
        chevron
      >
        <template #icon>
          <i v-if="item.finished" class="fal fa-check"></i>
          <i v-else class="fal fa-timer"></i>
        </template>
        <template #subtitle>
          <span v-if="item.finished">Finished, grade: <strong>{{ item.grade }}</strong></span>
          <span v-else>In progress</span>
        </template>
      </ui-mobile-item>
    </div>

    <ui-mobile-bottom>
      <ui-button confirm @click="$router.push('/choose')">
        Start a new assignment
        <!--
        <i class="fal fa-chevron-right"></i>
        -->
      </ui-button>
    </ui-mobile-bottom>

  </div>

</ui-page>
</template>

<script>

const SUBTYPES = {
  'ielts_academic_1': 'IELTS Academic Writing Task 1',
  'ielts_academic_2': 'IELTS Academic Writing Task 2',
  'ielts_general_1': 'IELTS General Training Writing Task 1',
  'ielts_general_2': 'IELTS General Training Writing Task 2',
};

export default {
  name: 'ViewAssignments',

  data() {
    return {
    };
  },

  computed: {
    assignmentList() {
      const names = this.$store.getters.assignmentNames.writing;
      var ret = this.$store.getters.assignments
        .filter((item) => item.started).map((item) => {
          var title = SUBTYPES[item.category] + ", assignment " + item.assignmentId;
          if(item.assignmentId <= names[item.category].length) {
            title = names[item.category][item.assignmentId-1];
          }
          return {
            category: item.category,
            name: SUBTYPES[item.category],
            title: title,
            number: item.assignmentId,
            id: item.id,
            started: item.started,
            finished: item.finished,
            grade: item.grade,
            time: item.time,
          };
        });
      return ret.sort((a, b) => b.time - a.time);
    },
  },

  methods: {
    showAssignment(id) {
      this.$router.push(`/assignment/${id}`);
    }
  },
}

</script>

<style>

.view-assignments .list {
  padding-top: 15px;
}

.view-assignments .item {
  display: flex; 
  align-items: center;
  padding: 10px 15px;
  border-radius: 999px;
  cursor: pointer;
}
.view-assignments .item:hover {
  background: #ecf0f1;
}

.view-assignments .item-icon {
  margin-right: 15px;
  margin-left: 2px;
  width: 32px;
  text-align: center;
  font-size: 2em;
}
.view-assignments I.fa-timer {
  color: #3498db;
}
.view-assignments I.fa-check {
  color: #27ae60;
}

.view-assignments .item-header {
  flex-grow: 999;
}
.view-assignments .item-header-title {
  font-size: 1.2em;
}
.view-assignments .item-header-subtitle {
  color: #7f8c8d;
  font-size: 0.9em;
}

.view-assignments .info {
  padding-top: 15px;
  font-size: 1.1em;
}

.view-assignments .item-time {
  width: 110px;
  text-align: right;
}

.view-assignments .item-buttons {
  width: 213px;
  text-align: right;
}

/*
 * Mobile styles start
 */

@media (max-width: 1024px) {

  .view-assignments .list {
      padding-top: 10px;
      padding-bottom: 85px;
  }

}
</style>