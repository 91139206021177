<template>
  <div class="app-login">

    <div class="app-login-container" v-if="state == 'email'">
      <div class="app-login-top">
        <div class="app-login-field">
          Your email address:
          <ui-edit
            ref="email"
            v-model="email.email"
            :error="!!email.error"
          />
          <div class="app-login-error" v-if="email.error">
            {{ email.error }}
          </div>
        </div>
        <ui-button
          :loading="email.loading"
          @click="changeEmail"
        >
          Change your email
        </ui-button>
      </div>
    </div>

    <div class="app-login-container" v-if="state == 'confirm'">
      <div class="app-login-top">
        <div class="app-login-text">
          A message with a confirmation code has been sent to your email address.
        </div>
        <div class="app-login-field">
          Confirmation code:
          <ui-edit
            ref="code"
            v-model="confirm.code"
            :error="!!confirm.error"
          />
          <div class="app-login-error" v-if="confirm.error">
            {{ confirm.error }}
          </div>
        </div>
        <ui-button
          :loading="confirm.loading"
          @click="confirmEmail"
        >
          Confirm your email
        </ui-button>
      </div>
    </div>

  </div>
</template>

<script>
import api from '@/lib/api.js';

export default {
  name: 'AppEmail',

  data() {
    return {
      state: 'email',
      email: {
        email: this.$store.getters.userEmail,
        error: null,
        loading: false,
      },
      confirm: {
        code: '',
        error: null,
        loading: false,
      },
    };
  },

  methods: {
    changeEmail() {
      this.email.error = null;
      this.email.email = this.email.email.trim();

      if(!this.email.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        this.email.error = 'Enter a valid email address';
        this.$refs.email.focus();
        return;
      } else if(this.email.email.toLowerCase() == this.$store.getters.userEmail) {
        this.email.error = 'Enter a new email address';
        this.$refs.email.focus();
        return;
      }

      this.email.loading = true;
      api.post('/user/email/change', {
        'email': this.email.email,
      }).then((response) => {
        this.email.loading = false;
        if(response.ok) {
          this.state = 'confirm';
        } else {
          switch(response.error) {
            case 'already_exists':
              this.email.error = 'Email address is already registered';
              this.$refs.email.focus();
              break;
            default:
              this.email.error = "Unknown error, please try again";
          }
        }
      }).catch((error) => {
        console.error(error);
        this.email.loading = false;
        this.email.error = "Unknown error, please try again";
      });
    },

    confirmEmail() {
      this.confirm.error = null;

      if(!this.confirm.code.trim()) {
        this.confirm.error = 'Confirmation code is required';
        this.$refs.code.focus();
        return;
      }

      this.confirm.loading = true;
      api.post('/user/email/confirm', {
        email: this.email.email,
        code: this.confirm.code,
      }).then((response) => {
        this.confirm.loading = false;
        if(response.ok) {
          this.$store.commit('email', this.email.email);
          this.$emit('email');
        } else {
          switch(response.error) {
            case 'invalid_code':
              this.confirm.error = 'Invalid confirmation code';
              this.$refs.code.focus();
              break;
            default:
              this.confirm.error = "Unknown error, please try again";
          }
        }
      }).catch((error) => {
        console.error(error);
        this.confirm.loading = false;
        this.confirm.error = "Unknown error, please try again";
      });
    },
  }
}

</script>

<style>

</style>
