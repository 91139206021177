<template>
  <div class="app-login">

    <div class="app-login-container" v-if="state == 'signup'">
      <div class="app-login-top">
        <div>
          Create your account to continue.
        </div>
        <ui-button
          big
          bg-color="db4a39"
          text-color="ffffff"
          @click="loginGoogle"
        >
          <i class="fab fa-google"></i>
          Sign up with Google
        </ui-button>
        <HFaceBookLogin 
          v-slot="fbLogin" 
          app-id="2115455095582303" 
          @onSuccess="loginFacebook" 
          scope="email,public_profile"
          fields="id,name,email,first_name,last_name,birthday"
        >
          <ui-button
            big
            bg-color="1877F2"
            text-color="ffffff"
            @click="fbLogin.initFBLogin"
          >
            <i class="fab fa-facebook"></i>
            Sign up with Facebook
          </ui-button>
        </HFaceBookLogin>
        <ui-button
          big
          @click="state = 'signupEmail'"
        >
          <i class="far fa-envelope"></i>
          Sign up with email
        </ui-button>
      </div>
      <div class="app-login-bottom">
        
        <a href="#" @click.prevent="state = 'login'">
          Already have an account? Log in.
        </a>
      </div>
    </div>

    <div class="app-login-container" v-if="state == 'signupEmail'">
      <div class="app-login-top">
        <div class="app-login-field">
          Your email address:
          <ui-edit
            ref="signupEmail"
            v-model="signup.email"
            :error="!!signup.emailError"
          />
          <div class="app-login-error" v-if="signup.emailError">
            {{ signup.emailError }}
          </div>
        </div>
        <div class="app-login-field">
          Your password:
          <ui-edit
            ref="signupPassword"
            password
            v-model="signup.password"
            :error="!!signup.passwordError"
          />
          <div class="app-login-error" v-if="signup.passwordError">
            {{ signup.passwordError }}
          </div>
        </div>
        <div class="app-login-field">
          Type the password again:
          <ui-edit
            ref="signupPassword2"
            password
            v-model="signup.password2"
            :error="!!signup.password2Error"
          />
          <div class="app-login-error" v-if="signup.password2Error">
            {{ signup.password2Error }}
          </div>
        </div>
        <ui-button
          :loading="signup.loading"
          @click="signupEmail"
        >
          Create your account
        </ui-button>
      </div>
      <div class="app-login-bottom">
        <a href="#" @click.prevent="state = 'signup'">
          Use a different signup method.
        </a>
      </div>
    </div>

    <div class="app-login-container" v-if="state == 'confirm'">
      <div class="app-login-top">
        <div class="app-login-text">
          A message with a confirmation code has been sent to your email address.
        </div>
        <div class="app-login-field">
          Confirmation code:
          <ui-edit
            ref="confirmCode"
            v-model="confirm.code"
            :error="!!confirm.codeError"
          />
          <div class="app-login-error" v-if="confirm.codeError">
            {{ confirm.codeError }}
          </div>
        </div>
        <div class="app-login-checkbox">
          <ui-checkbox v-model="confirm.accept">
            I accept&nbsp;
            <a href="https://www.englishvoice.com/legal/terms.html" target="_blank">terms of service</a>
            &nbsp;and&nbsp;
            <a href="https://www.englishvoice.com/legal/privacy.html" target="_blank">privacy policy</a>
          </ui-checkbox>
          <div class="app-login-error" v-if="confirm.acceptError">
            {{ confirm.acceptError }}
          </div>
        </div>
        <div class="app-login-checkbox">
          <ui-checkbox v-model="confirm.permanent">
            Remember me on this computer
          </ui-checkbox>
        </div>
        <ui-button
          :loading="confirm.loading"
          @click="confirmCode"
        >
          Confirm your email
        </ui-button>
      </div>
      <div class="app-login-bottom">
        <a href="#" @click.prevent="state = 'signup'">
          Use a different signup method.
        </a>
      </div>
    </div>

    <div class="app-login-container" v-if="state == 'accept'">
      <div class="app-login-top">
        <div class="app-login-text">
          Please accept our latest terms of service to continue to your account.
        </div>
        <div class="app-login-checkbox">
          <ui-checkbox v-model="accept.accept">
            I accept&nbsp;
            <a href="https://www.englishvoice.com/legal/terms.html" target="_blank">terms of service</a>
            &nbsp;and&nbsp;
            <a href="https://www.englishvoice.com/legal/privacy.html" target="_blank">privacy policy</a>
          </ui-checkbox>
          <div class="app-login-error" v-if="accept.acceptError">
            {{ accept.acceptError }}
          </div>
        </div>
        <div class="app-login-checkbox">
          <ui-checkbox v-model="accept.permanent">
            Remember me on this computer
          </ui-checkbox>
        </div>
        <ui-button
          :loading="accept.loading"
          @click="acceptTerms"
        >
          Go to your account
        </ui-button>
      </div>
      <div class="app-login-bottom">
        <a href="#" @click.prevent="state = 'signup'">
          Use a different signup method.
        </a>
      </div>
    </div>

    <div class="app-login-container" v-if="state == 'login'">
      <div class="app-login-top">
        <div>
          Log in to your account.
        </div>
        <ui-button
          big
          bg-color="db4a39"
          text-color="ffffff"
          @click="loginGoogle"
        >
          <i class="fab fa-google"></i>
          Log in with Google
        </ui-button>
        <HFaceBookLogin 
          v-slot="fbLogin" 
          app-id="2115455095582303" 
          @onSuccess="loginFacebook" 
          scope="email,public_profile"
          fields="id,name,email,first_name,last_name,birthday"
        >
          <ui-button
            big
            bg-color="1877F2"
            text-color="ffffff"
            @click="fbLogin.initFBLogin"
          >
            <i class="fab fa-facebook"></i>
            Log in with Facebook
          </ui-button>
        </HFaceBookLogin>
        <ui-button
          big
          @click="state = 'loginEmail'"
        >
          <i class="far fa-envelope"></i>
          Log in with email
        </ui-button>
      </div>
      <div class="app-login-bottom">
        
        <a href="#" @click.prevent="state = 'signup'">
          No account yet? Sign up here.
        </a>
      </div>
    </div>

    <div class="app-login-container" v-if="state == 'loginEmail'">
      <div class="app-login-top">
        <div class="app-login-field">
          Your email address:
          <ui-edit
            ref="loginEmail"
            v-model="login.email"
            :error="!!login.emailError"
          />
          <div class="app-login-error" v-if="login.emailError">
            {{ login.emailError }}
          </div>
        </div>
        <div class="app-login-field">
          Your password:
          <ui-edit
            ref="loginPassword"
            password
            v-model="login.password"
            :error="!!login.passwordError"
          />
          <div class="app-login-error" v-if="login.passwordError">
            {{ login.passwordError }}
          </div>
        </div>
        <div class="app-login-checkbox">
          <ui-checkbox v-model="login.permanent">
            Remember me on this computer
          </ui-checkbox>
        </div>
        <ui-button
          :loading="login.loading"
          @click="loginEmail"
        >
          Log in to your account
        </ui-button>
      </div>
      <div class="app-login-bottom">
        <a href="#" @click.prevent="state = 'login'">
          Use a different login method.
        </a>
      </div>
    </div>

  </div>
</template>

<script>
import { googleTokenLogin } from "vue3-google-login"

import { HFaceBookLogin } from '@healerlab/vue3-facebook-login';

import api from '@/lib/api.js';

export default {
  name: 'AppLogin',

  components: {
    HFaceBookLogin
  },

  props: {
    defaultLogin: Boolean,
  },

  data() {
    return {
      state: this.defaultLogin ? 'login' : 'signup',
      signup: {
        email: '',
        emailError: null,
        password: '',
        passwordError: null,
        password2: '',
        password2Error: null,
        loading: false,
      },
      confirm: {
        code: '',
        codeError: null,
        accept: false,
        acceptError: null,
        permanent: true,
        loading: false,
      },
      accept: {
        email: null,
        accept: false,
        acceptError: null,
        permanent: true,
        loading: false,
      },
      login: {
        email: '',
        emailError: null,
        password: '',
        passwordError: null,
        loading: false,
        permanent: true,
      },
      error: '',
      loading: false,
    };
  },

  methods: {
    signupEmail() {
      this.signup.emailError = null;
      this.signup.passwordError = null;
      this.signup.password2Error = null;
      this.signup.email = this.signup.email.trim();
      this.signup.password = this.signup.password.trim();
      this.signup.password2 = this.signup.password2.trim();

      if(!this.signup.email) {
        this.signup.emailError = 'Email address is required';
        this.$refs.signupEmail.focus();
        return;
      } else if(!this.signup.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        this.signup.emailError = 'Enter a valid email address';
        this.$refs.signupEmail.focus();
        return;
      } else if (this.signup.password.length < 8) {
        this.signup.passwordError = 'Password must have at least 8 characters';
        this.$refs.signupPassword.focus();
        return;
      }
      else if (this.signup.password !== this.signup.password2) {
        this.signup.password2Error = 'Passwords do not match';
        this.$refs.signupPassword2.focus();
        return;
      }

      this.signup.loading = true;
      api.post('/user/signup', {
        'email': this.signup.email,
        'password': this.signup.password,
      }).then((response) => {
        this.signup.loading = false;
        if(response.ok) {
          this.state = 'confirm';
        } else {
          switch(response.error) {
            case 'already_exists':
              this.signup.emailError = 'Email address is already registered';
              this.$refs.signupEmail.focus();
              break;
            default:
              this.signup.emailError = "Unknown error, please try again";
          }
        }
      }).catch((error) => {
        console.error(error);
        this.signup.loading = false;
        this.signup.emailError = "Unknown error, please try again";
      });
    },

    confirmCode() {
      this.confirm.codeError = null;
      this.confirm.acceptError = null;

      if(!this.confirm.code.trim()) {
        this.confirm.codeError = 'Confirmation code is required';
        this.$refs.confirmCode.focus();
        return;
      }
      if(!this.confirm.accept) {
        this.confirm.acceptError = 'You must accept the terms of service';
        return;
      }

      this.confirm.loading = true;
      api.post('/user/confirm', {
        email: this.signup.email,
        code: this.confirm.code,
        permanent: this.confirm.permanent,
      }).then((response) => {
        this.confirm.loading = false;
        if(response.ok) {
          if(response.assignments) {
            this.$store.commit('assignments', response.assignments);
          }
          this.$store.commit('user', response.user);
          this.$emit('login');
        } else {
          switch(response.error) {
            case 'invalid_code':
              this.confirm.codeError = 'Invalid confirmation code';
              this.$refs.confirmCode.focus();
              break;
            default:
              this.confirm.codeError = "Unknown error, please try again";
          }
        }
      }).catch((error) => {
        console.error(error);
        this.confirm.loading = false;
        this.confirm.codeError = "Unknown error, please try again";
      });
    },

    acceptTerms() {
      this.accept.acceptError = null;

      if(!this.accept.accept) {
        this.accept.acceptError = 'You must accept the terms of service';
        return;
      }

      this.accept.loading = true;
      api.post('/user/accept', {
        email: this.accept.email,
        permanent: this.accept.permanent,
      }).then((response) => {
        this.accept.loading = false;
        if(response.ok) {
          if(response.assignments) {
            this.$store.commit('assignments', response.assignments);
          }
          this.$store.commit('user', response.user);
          this.$emit('login');
        } else {
          this.accept.acceptError = "Unknown error, please try again";
        }
      }).catch((error) => {
        console.error(error);
        this.accept.loading = false;
        this.accept.acceptError = "Unknown error, please try again";
      });
    },

    loginEmail() {
      this.login.emailError = null;
      this.login.passwordError = null;
      this.login.email = this.login.email.trim();
      this.login.password = this.login.password.trim();

      if(!this.login.email) {
        this.login.emailError = 'Email address is required';
        this.$refs.loginEmail.focus();
        return;
      } else if(!this.login.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        this.login.emailError = 'Enter a valid email address';
        this.$refs.loginEmail.focus();
        return;
      } else if(!this.login.password) {
        this.login.passwordError = 'Password is required';
        this.$refs.loginPassword.focus();
        return;
      }

      this.login.loading = true;
      api.post('/user/login', {
        'email': this.login.email,
        'password': this.login.password,
        'permanent': this.login.permanent,
      }).then((response) => {
        this.login.loading = false;
        if(response.ok) {
          if(response.user.needAcceptTerms) {
            this.accept.email = this.login.email;
            this.accept.permanent = this.login.permanent;
            this.state = 'accept';
          } else {
            if(response.assignments) {
              this.$store.commit('assignments', response.assignments);
            }
            this.$store.commit('user', response.user);
            this.$emit('login');
          }
        } else {
          switch(response.error) {
            case 'not_exists':
              this.login.emailError = 'This email address is not registered';
              this.$refs.loginEmail.focus();
              break;
            case 'not_confirmed':
              this.login.emailError = 'Email address is not confirmed yet';
              this.$refs.loginEmail.focus();
              break;
            case 'invalid_password':
              this.login.passwordError = 'Invalid password';
              this.$refs.loginPassword.focus();
              break;
            default:
              this.login.emailError = "Unknown error, please try again";
          }
        }
      }).catch((error) => {
        console.error(error);
        this.login.loading = false;
        this.login.emailError = "Unknown error, please try again";
      });
    },

    loginGoogle() {
      googleTokenLogin().then((response) => {
        api.post('/user/google', {
          'token': response.access_token,
        }).then((response) => {
          if(response.ok) {
            if(response.user.needAcceptTerms) {
              this.accept.email = response.user.email;
              this.state = 'accept';
            } else {
              if(response.assignments) {
                this.$store.commit('assignments', response.assignments);
              }
              this.$store.commit('user', response.user);
              this.$emit('login');
            }
          }
        }).catch((_) => { console.log(_)});
      }).catch((_) => {console.log(_)});
    },

    loginFacebook(response) {
      api.post('/user/facebook', {
        'token': response.authResponse.accessToken,
      }).then((response) => {
        if(response.ok) {
          if(response.user.needAcceptTerms) {
            this.accept.email = response.user.email;
            this.state = 'accept';
          } else {
            if(response.assignments) {
              this.$store.commit('assignments', response.assignments);
            }
            this.$store.commit('user', response.user);
            this.$emit('login');
          }
        }
      }).catch((_) => { console.log(_)});
    },

  }
}

</script>

<style>

.app-login BUTTON {
  margin-top: 10px;
  width: 100% !important;
}

.app-login-top {
  padding: 5px 0;
  min-height: 250px;
}

.app-login-bottom {
  padding: 5px 0;
  text-align: center;
}

.app-login-text {
  margin-bottom: 10px;
}

.app-login-field {
  margin-bottom: 5px;
}
.app-login-field INPUT {
  width: 100%;
}

.app-login-checkbox {
  margin-top: 10px;
  margin-bottom: 5px;
}

.app-login-error {
  font-size: 0.85em;
  color: #e74c3c;
}

/*
 * Mobile styles start
 */

@media (max-width: 600px) {

  .app-login-top {
    position: fixed;
    top: 60px;
    bottom: 60px;
    left: 15px;
    right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 0;
  }

  .app-login BUTTON {
    margin-top: 20px;
  }

  .app-login-top .ui-edit INPUT {
    font-size: 24px;
  }

  .app-login-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    border-top: 1px solid #bdc3c7;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .app-login-bottom A {
    width: 100%;
    padding: 10px 0;
  }
}

</style>
