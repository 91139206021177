<template>
  <div class="assignment-words">
    Word Count: <strong>{{ wordCount }}</strong>
  </div>
</template>

<script>
export default {
  name: 'AssignmentWords',
  props: {
    text: String
  },
  computed: {
    wordCount() {
      if (!this.text) {
        return 0;
      }
      return this.text.trim().split(/\s+/).length;
    }
  }
};
</script>
