<template>
  <div class="app-subscribe">
    <div class="app-subscribe-top">
      <p>
        You have reached your free assignment quota.
      </p>
      <hr/>
      <p>
        To complete more assignments, you need to subscribe to our AI plan which includes:
      </p>
      <ul>
        <li>Unlimited writing assignments.</li>
        <li>Detailed assignment grading by AI.</li>
        <li>AI assistant to help you with writing.</li>
      </ul>
    </div>
    <div class="app-subscribe-bottom">
      <ui-button
        confirm
        big
        :loading="loading"
        @click="subscribe"
      >
        <!--
          Subscribe for {{ $store.getters.subscriptionPrice.price }} {{ $store.getters.subscriptionPrice.currency }} / month
        -->
        Subscribe now
      </ui-button>
    </div>
  </div>
</template>

<script>
import api from '@/lib/api.js';

export default {

  name: 'AppSubscribe',
  data() {
    return {
      loading: false
    };
  },
  methods: {
    subscribe() {
      this.loading = true;
      api.post('/subscription/start', {
        successUrl: window.location.origin + '/#/assignment/' + this.id,
        cancelUrl: window.location.origin + '/#/assignment/' + this.id,
      }).then((response) => {
        window.location = response.redirect;
      }).catch((error) => {
        this.loading = false;
        console.log('Error', error);
      });
    },
  }
};
</script>

<style>

.app-subscribe HR {
  margin: 13px 0;
  border: none;
  border-bottom: 1px solid #95a5a6;
}
.app-subscribe-bottom {
  padding: 10px 0;
}
.app-subscribe-bottom BUTTON {
  width: 100% !important;
}

/*
 * Mobile styles start
 */

@media (max-width: 600px) {

  .app-subscribe-top {
    position: fixed;
    top: 60px;
    bottom: 60px;
    left: 15px;
    right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .app-subscribe-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    border-top: 1px solid #bdc3c7;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .app-subscribe-bottom BUTTON {
    font-size: 24px;
    padding: 10px 20px;
    width: 100% !important;
  }

}

</style>
