<template>
  <label class="ui-checkbox">
    <input type="checkbox" :checked="currentValue" @change="updateValue($event.target.checked)"/>
    <span class="ui-checkbox-overlay">
      <i class="fas fa-check" v-if="currentValue"></i>
    </span>
    <div>
      <slot></slot>
    </div>
  </label>
</template>

<script>
export default {
  name: 'UiCheckbox',
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      currentValue: this.modelValue,
    };
  },
  methods: {
    updateValue(value) {
      this.currentValue = value;
      this.$emit('update:modelValue', value);
    }
  },
  computed: {
    // Your computed properties here
  },
  mounted() {
    // Lifecycle hook
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
    }
  },  
};
</script>

<style>
.ui-checkbox {
  display: flex;
  align-items: center;
}
.ui-checkbox INPUT[type=checkbox] {
  display: none;
}
.ui-checkbox .ui-checkbox-overlay {
  width: 20px;
  height: 20px;
  border: 1px solid #7f8c8d;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
}
.ui-checkbox .ui-checkbox-overlay I {
  font-size: 22px;
  position: absolute;
  top: -2px;
  left: 2px;
  color: #3498db;
}
</style>