<template>
  <button
    class="ui-button"
    :class="{ 
      'ui-button--small': small,
      'ui-button--big': big,
      'ui-button--huge': huge,
      'ui-button--confirm': confirm
    }"
    :style="{
      width: computeWidth,
      backgroundColor: backgroundColor,
      color: innerColor,
    }"
    :disabled="disabled || loading"
  >
    <div class="inner">
      <i class="fas fa-spinner fa-spin" v-if="loading"></i>
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'UiButton',
  props: {
    small: Boolean,
    big: Boolean,
    huge: Boolean,
    confirm: Boolean,
    disabled: Boolean,
    width: {
      type: Number,
      default: 0
    },
    loading: Boolean,
    bgColor: String,
    textColor: String,
  },

  computed: {
    computeWidth() {
      if (this.width > 0) {
        return `${this.width}px`;
      }
      return 'auto';
    },

    backgroundColor() {
      if(this.bgColor) {
        return '#' + this.bgColor;
      } else {
        return null;
      }
    },

    innerColor() {
      if(this.textColor) {
        return '#' + this.textColor;
      } else {
        return null;
      }
    },
  },

  methods: {
    clicked() {
      this.$emit('click');
    }
  }
};
</script>

<style>
.ui-button {
  border: 1px solid #7f8c8d;
  background: #ecf0f1;
  color: black;
  outline: none;
  cursor: pointer;
  border-radius: 999px;
  font-size: 16px;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 12px;
}
.ui-button:disabled {
  cursor: not-allowed;
  opacity: .65;
}
.ui-button .inner {
  margin: 1px;
}
.ui-button:active:not(:disabled) .inner {
  margin: 1.5px 0.5px 0.5px 1.5px;
}
.ui-button:hover:not(:disabled) {
  background: #dfe3e4;
}
.ui-button .inner .fa-spinner {
  margin-right: 6px;
  font-size: 0.95em;
}

.ui-button--small {
  font-size: 14px;
  padding: 3px 8px;
}
.ui-button--big {
  font-size: 20px;
  padding: 6px 16px;
}
.ui-button--huge {
  font-size: 26px;
  padding: 10px 22px;
}

.ui-button--confirm {
  background: #3498db;
  border-color: #3498db;
  color: white;
}
.ui-button--confirm:hover:not(:disabled) {
  background: #2790d3;
  border-color: #2790d3;
}

</style>