<template>
  <span class="ui-edit"
    :class="{ 
      'ui-edit-inline': inline,
      'ui-edit-error': error,
    }"
  >
    <input
      v-if="number"
      type="number"
      :min="min"
      :max="max"
      :value="currentValue"
      :disabled="disabled"
      @change="updateValue($event.target.value)"
    />
    <input
      v-else-if="password"
      type="password"
      :value="currentValue"
      :disabled="disabled"
      @change="updateValue($event.target.value)"
    />
    <input
      v-else
      type="text"
      :value="currentValue"
      :disabled="disabled"
      @change="updateValue($event.target.value)"
    />
  </span>
</template>

<script>
export default {
  name: 'UiEdit',
  props: {
    modelValue: {
      type: [String, Number],
      required: true
    },
    disabled: Boolean,
    inline: Boolean,
    number: Boolean,
    password: Boolean,
    error: Boolean,
    min: Number,
    max: Number
  },
  data() {
    return {
      currentValue: this.modelValue,
    };
  },
  methods: {
    updateValue(value) {
      this.currentValue = value;
      this.$emit('update:modelValue', value);
    },

    focus() {
      this.$el.querySelector('INPUT').focus();
    },
  },
  computed: {
    // Your computed properties here
  },
  mounted() {
    // Lifecycle hook
  },
};
</script>

<style>
.ui-edit INPUT[type=text],
.ui-edit INPUT[type=number],
.ui-edit INPUT[type=password] {
  border: 1px solid #7f8c8d;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 16px;
  outline: none;
  background: white !important;
}
.ui-edit INPUT[type=number] {
  padding-right: 4px;
}
.ui-edit-inline {
  display: inline-block;
  margin: 0 5px;
}

.ui-edit-inline INPUT[type=text],
.ui-edit-inline INPUT[type=number],
.ui-edit-inline INPUT[type=password] {
  width: 60px;
}

.ui-edit-error INPUT[type=text], 
.ui-edit-error INPUT[type=number], 
.ui-edit-error INPUT[type=password] {
  border: 1px solid #e74c3c !important;
}

.ui-edit INPUT:-webkit-autofill {
  background-color: white !important;
}

</style>