<template>
  <ui-page title="Start an assignment">
    <div class="view-choose" :class="{ opened: mobileOpened }">
      <div class="mobile picker" @click="mobileToggle">
        <div>
          <div class="picker-title ellipsis">
            {{ secondLevelItem.name }}
          </div>
          <div class="picker-subtitle">
            Time: <strong>{{ secondLevelItem.time }}</strong>.
            Length: <strong>{{ secondLevelItem.words }} words</strong>.
          </div>
        </div>
        <div class="picker-icon">
          <i class="fal fa-chevron-down" v-if="!mobileOpened"></i>
          <i class="fal fa-chevron-up" v-else></i>
        </div>
      </div>
      <div class="columns" :class="'columns-' + numberOfColumns">
        <!--
        <div class="column">
          <div class="column-header">
            1. Choose exam:
          </div>
          <div class="column-content">
            <div
              class="column-item"
              :class="{ active: firstLevel == index }"
              @click="setFirstLevel(index)"
              v-for="(item, index) in firstLevelItems"
              :key="index"
            >
              <div>
                <div class="column-item-first ellipsis">
                  <div>{{ item.name }}</div>
                </div>
                <div class="column-item-second ellipsis">
                  {{ item.subname }}
                </div>
              </div>
              <div><i class="fal fa-chevron-right"></i></div>
            </div>
          </div>
        </div>
        -->

        <div class="column column-tasks">
          <div class="column-header">
            1. Choose task:
          </div>
          <div class="column-content">
            <div
              class="column-item"
              :class="{ active: secondLevel == index }"
              @click="setSecondLevel(index)"
              v-for="(item, index) in secondLevelItems"
              :key="index"
            >
              <div>
                <div class="column-item-first ellipsis">
                  {{ item.name }}
                </div>
                <div class="column-item-second ellipsis">
                  Time: <strong>{{ item.time }}</strong>.
                  Length: <strong>{{ item.words }} words</strong>.
                </div>
              </div>
              <div><i class="fal fa-chevron-right"></i></div>
            </div>
          </div>
        </div>

        <div class="column column-assignments" v-if="numberOfColumns == 3">
          <div class="column-header">
            2. Choose assignment:
          </div>
          <div class="column-content">
            <div
              class="column-item"
              @click="startAssignment(item.id)"
              v-for="(item, index) in thirdLevelItems"
              :key="index"
            >
              <div>
                <div class="column-item-first ellipsis">
                  {{ item.name }}
                </div>
                <div class="column-item-second ellipsis">
                  <span v-if="!item.started">Not started</span>
                  <span v-else-if="!item.finished">In progress</span>
                  <span v-else>Finished, grade: <strong>{{ item.grade }}</strong></span>
                </div>
              </div>
              <div class="assignment-icon desktop">
                <i :class="'fal fa-' + item.icon"></i>
              </div>
              <div class="assignment-icon mobile">
                <i class="fal fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ui-dialog
      :width="400"
      title="Sign up to start your assignment"
      no-buttons
      @close="dialogs.login = false"
      v-if="dialogs.login"
    >
      <app-login @login="didLogin"/>
    </ui-dialog>

    <ui-dialog
      :width="400"
      title="Assignment limit reached"
      no-buttons
      @close="dialogs.subscribe = false"
      v-if="dialogs.subscribe"
    >
      <AppSubscribe/>
    </ui-dialog>

  </ui-page>
</template>

<script>

import api from '@/lib/api.js';

const TYPES = [
  {
    name: 'IELTS Writing',
    subname: 'International English Language Testing System',
    type: 'writing',
    subtypes: [
      {
        name: 'IELTS Academic Writing Task 1',
        category: 'ielts_academic_1',
        words: '150',
        time: '20 minutes',
        assignments: 50,
      },
      {
        name: 'IELTS Academic Writing Task 2',
        category: 'ielts_academic_2',
        words: '250',
        time: '40 minutes',
        assignments: 50,
      },
      {
        name: 'IELTS General Training Writing Task 1',
        category: 'ielts_general_1',
        words: '150',
        time: '20 minutes',
        assignments: 50,
      },
      {
        name: 'IELTS General Training Writing Task 2',
        category: 'ielts_general_2',
        words: '250',
        time: '40 minutes',
        assignments: 50,
      },
    ]
  }
]

export default {
  name: 'ViewPicker',

  data() {
    return {
      firstLevel: this.$store.getters.chooseView.level1 ?? 0,
      secondLevel: this.$store.getters.chooseView.level2 ?? 0,
      startId: null,
      dialogs: {
        login: false,
        subscribe: false,
      },
      mobileOpened: false,
    };
  },

  computed: {
    numberOfColumns() {
      if(this.secondLevel === null) {
        return 2;
      } else {
        return 3;
      }
    },

    firstLevelItems() {
      var ret = [];
      TYPES.forEach((type) => {
        ret.push({
          name: type.name,
          subname: type.subname
        });
      });
      return ret;
    },

    secondLevelItems() {
      var ret = [];
      TYPES[this.firstLevel].subtypes.forEach((type) => {
        ret.push(type);
      });
      return ret;
    },

    thirdLevelItems() {
      if(this.secondLevel === null) {
        return [];
      }

      const category = TYPES[this.firstLevel].subtypes[this.secondLevel].category;
      const names = this.$store.getters.assignmentNames.writing;
      const length = names[category].length;
      var ret = [];
      for(var i = 0; i < length; i++) {
        const assignment = this.lastAssignment(TYPES[this.firstLevel].subtypes[this.secondLevel].category, i+1);
        ret.push({
          id: i+1,
          name: names[category][i],
          started: assignment.started,
          finished: assignment.finished,
          grade: assignment.grade,
          icon: assignment.started ? (assignment.finished ? 'check' : 'timer') : 'chevron-right',
        });
      }
      return ret;
    },

    secondLevelItem() {
      return TYPES[this.firstLevel].subtypes[this.secondLevel];
    }

  },

  methods: {
      setFirstLevel(index) {
      this.firstLevel = index;
      this.secondLevel = null;
      this.$store.commit('chooseViewLevel1', index);
    },

    setSecondLevel(index) {
      this.secondLevel = index;
      this.$store.commit('chooseViewLevel2', index);
      this.mobileOpened = false;
    },

    startAssignment(id) {
      this.startId = id;

      const total = this.$store.getters.assignments.length;
      //const finished = this.$store.getters.assignments.filter((item) => item.finished).length;
      if(!this.$store.getters.isLogged && total >= 2) {
        this.dialogs.login = true;
        return;
      }

      const assignment = this.lastAssignment(TYPES[this.firstLevel].subtypes[this.secondLevel].category, id);
      // Always redirect if the assignment is started, otherwise check if the user has subscription
      if(assignment.id && assignment.started) {
        this.$router.push(`/assignment/${assignment.id}`);
        return;
      /*
      } else if (finished >= 1 && !this.$store.getters.hasSubscription) {
        this.dialogs.subscribe = true;
        return;
      */
      } else if(assignment.id) {
        this.$router.push(`/assignment/${assignment.id}`);
        return;
      }

      api.post('/assignment/create', {
        id,
        type: TYPES[this.firstLevel].type,
        category: TYPES[this.firstLevel].subtypes[this.secondLevel].category,
      }).then((response) => {
        this.$store.commit('addAssignment', {
          id: response.assignment.id,
          assignmentId: id,
          type: TYPES[this.firstLevel].type,
          category: TYPES[this.firstLevel].subtypes[this.secondLevel].category,
          started: false,
          finished: false,
          grade: null,
          time: Math.floor(Date.now() / 1000),
        });
        this.$router.push(`/assignment/${response.assignment.id}`);
      }).catch(() => {
        console.error("Failed to finish");
      });
    },

    lastAssignment(category, id) {
      const assignments = this.$store.getters.assignments;
      for(let i = assignments.length - 1; i >= 0; i--) {
        if(assignments[i].category == category && assignments[i].assignmentId == id) {
          return assignments[i];
        }
      }
      return {
        id: null,
        started: false,
        finished: false,
        grade: null,
      }
    },

    didLogin() {
      this.startAssignment(this.startId);
    },

    mobileToggle() {
      this.mobileOpened = !this.mobileOpened;
    }
  },
}

</script>

<style>

.view-choose .columns {
  display: flex;
  gap: 10px;
  height: calc(100vh - 60px);
}

.view-choose .column {
  width: calc(50% - 5px);
  display: flex;
  flex-direction: column;
  height: 100%;
}
/*
.view-choose .columns-3 .column:nth-child(1) {
  width: 32%;
}
.view-choose .columns-3 .column:nth-child(2) {
  width: calc(36% - 20px);
}
.view-choose .columns-3 .column:nth-child(3) {
  width: 32%;
}
*/

.view-choose .column-header {
  font-size: 1.35em;
  font-weight: 600;
  padding: 10px 20px;
  margin-top: 15px;
}

.view-choose .column-content {
  overflow-y: auto;
  flex-grow: 999;
  height: 100%;
}

.view-choose .column-item {
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 999px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.view-choose .column-item.active,
.view-choose .column-item:hover {
  background: #ecf0f1;
}
.view-choose .column-item I {
  color: #7f8c8d;
  font-size: 2em;
}
.view-choose .column-item > DIV:first-child {
  width: calc(100% - 30px);
}

.view-choose .column-item-first {
  font-size: 1.2em;
}
.view-choose .column-item-second {
  color: #7f8c8d;
  font-size: 0.9em;
}

.view-choose .assignment-icon {
  width: 32px;
  text-align: center;
  margin-right: -10px;
}
.view-choose .assignment-icon .fa-timer {
  color: #3498db;
}
.view-choose .assignment-icon .fa-check {
  color: #27ae60;
}

/*
 * Mobile styles start
 */

@media (max-width: 1024px) {

  .view-choose:not(.opened) .column-tasks {
    display: none;
  }

  .view-choose .columns {
    position: fixed;
    top: 130px;
    bottom: 0;
    left: 15px;
    right: 0;
  }

  .view-choose .column {
    width: 100%;
  }

  .view-choose .column-header {
    display: none;
  }

  .view-choose .column-item {
    padding: 6px 0;
    margin: 5px 0;
    border-radius: 0;
  }
  .view-choose .column-item > DIV:first-child {
    width: calc(100% - 40px);
  }
  .view-choose .assignment-icon {
    margin: 0;
  }
  .view-choose .column-item I {
    font-size: 1.5em;
  }

  .view-choose .picker {
    margin: 0 -15px;
    padding: 12px 15px 12px 15px;
    border-bottom: 1px solid #bdc3c7;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .view-choose .picker > DIV:first-child {
    width: calc(100% - 40px);
  }
  .view-choose .picker-title {
    font-size: 1.2em;
    font-weight: 600;
  }
  .view-choose .picker-subtitle {
    font-size: 0.9em;
  }

  .view-choose .picker I {
    color: #7f8c8d;
    font-size: 1.5em;
  }

  .view-choose .column-tasks {
    position: fixed;
    background: white;
    top: 126px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 15px;
  }

  .view-choose .column-tasks .column-item.active {
    background: white;
  }
  .view-choose .column-tasks I {
    display: none;
  }
  .view-choose .column-tasks .column-item > DIV:first-child {
    width: 100%;
  }

}

</style>