import { createRouter, createWebHashHistory } from 'vue-router'
import IndexView from '../views/Index.vue'
import AssignmentView from '../views/Assignment.vue'
import AssignmentsView from '../views/Assignments.vue'
import AccountView from '../views/Account.vue'
import ChooseView from '../views/Choose.vue'
import ConfirmView from '../views/Confirm.vue'
import SubscribeView from '../views/Subscribe.vue'
import EmptyView from '../views/Empty.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/choose',
    name: 'choose',
    component: ChooseView,
  },
  {
    path: '/assignments',
    name: 'assignments',
    component: AssignmentsView,
  },
  {
    path: '/assignment/:id',
    name: 'assignment',
    component: AssignmentView,
    props: route => ({ id: route.params.id })
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView,
  },
  {
    path: '/account/:link',
    name: 'account.link',
    component: AccountView,
    props: route => ({ initialLink: route.params.link })
  },
  {
    path: '/confirm/:email/:code',
    name: 'confirm',
    component: ConfirmView,
    props: route => ({ email: route.params.email, code: route.params.code })
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: SubscribeView,
  },
  {
    path: '/empty',
    name: 'empty',
    component: EmptyView,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
