<template>
  <div class="assignment-overlay">
    <div class="desktop">
      <div class="assignment-overlay-timer">
        <ui-checkbox v-model="startTimer">
          <span>Set timer to</span>
          <ui-edit
            inline
            number
            :min="1"
            :max="60"
            :disabled="!startTimer"
            v-model="currentTime"
          />
          <span>minutes</span>
        </ui-checkbox>
      </div>
      <ui-button huge confirm @click="$emit('start', { start: startTimer, time: currentTime })">Start writing!</ui-button>              
    </div>
    <div class="assignment-overlay-info mobile">
      Click the button to start your assignment.
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssignmentOverlay',

  props: {
    time: Number,
  },
  
  data() {
    return {
      currentTime: this.time,
      startTimer: true,
    };
  },
};
</script>

<style>
.assignment-overlay {
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
.assignment-overlay-timer {
  margin-bottom: 10px;
}
.assignment-overlay > DIV {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.assignment-overlay-info {
  font-size: 1.7em;
  text-align: center;
  color: #7f8c8d;
  margin: 0 20px;
}
</style>