<template>
  <div class="app-login">

    <div class="app-login-top">
      <div class="app-login-field" v-if="$store.getters.hasPassword">
        Your current password:
        <ui-edit
          ref="oldPassword"
          password
          v-model="oldPassword"
          :error="!!oldPasswordError"
        />
        <div class="app-login-error" v-if="oldPasswordError">
          {{ oldPasswordError }}
        </div>
      </div>
      <div class="app-login-field">
        Your new password:
        <ui-edit
          ref="newPassword"
          password
          v-model="newPassword"
          :error="!!newPasswordError"
        />
        <div class="app-login-error" v-if="newPasswordError">
          {{ newPasswordError }}
        </div>
      </div>
      <div class="app-login-field">
        Type the password again:
        <ui-edit
          ref="newPassword2"
          password
          v-model="newPassword2"
          :error="!!newPassword2Error"
        />
        <div class="app-login-error" v-if="newPassword2Error">
          {{ newPassword2Error }}
        </div>
      </div>
      <ui-button
        :loading="loading"
        @click="changePassword"
      >
        Change your password
      </ui-button>
    </div>

  </div>
</template>

<script>

import api from '@/lib/api.js';

export default {
  name: 'AppPassword',

  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      oldPasswordError: null,
      newPasswordError: null,
      newPassword2Error: null,
      loading: false,
    };
  },

  methods: {
    changePassword() {
      this.oldPasswordError = null;
      this.newPasswordError = null;
      this.newPassword2Error = null;
      this.oldPassword = this.oldPassword.trim();
      this.newPassword = this.newPassword.trim();
      this.newPassword2 = this.newPassword2.trim();

      if (this.$store.getters.hasPassword && !this.oldPassword.length) {
        this.oldPasswordError = 'Provide your current password';
        this.$refs.oldPassword.focus();
        return;
      }
      if (this.newPassword.length < 8) {
        this.newPasswordError = 'Password must have at least 8 characters';
        this.$refs.newPassword.focus();
        return;
      }
      else if (this.newPassword !== this.newPassword2) {
        this.newPassword2Error = 'Passwords do not match';
        this.$refs.newPassword2.focus();
        return;
      }

      this.loading = true;
      api.post('/user/password', {
        'oldPassword': this.oldPassword,
        'newPassword': this.newPassword,
      }).then((response) => {
        this.loading = false;
        if(response.ok) {
          this.$store.commit('hasPassword');
          this.$emit('password');
        } else {
          switch(response.error) {
            case 'invalid_password':
              this.oldPasswordError = 'Invalid current password';
              this.$refs.oldPassword.focus();
              break;
            default:
              this.newPasswordError = "Unknown error, please try again";
          }
        }
      }).catch(() => {
        this.loading = false;
        this.newPasswordError = "Unknown error, please try again";
      });
    },
  }
}

</script>

<style>

</style>
