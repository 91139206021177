<template>
  <span>{{ formattedDate }}</span>
</template>

<script>
export default {
  name: 'UiTime',
  props: {
    timestamp: {
      type: Number,
      required: true
    },
    format: {
      type: String,
      default: 'date'
    },
  },
  computed: {
    formattedDate() {
      if(this.format == 'relative') {
        return new Date(this.timestamp * 1000).toLocaleDateString([], {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
      }
      return new Date(this.timestamp * 1000).toLocaleDateString([], {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    }
  }
}
</script>

