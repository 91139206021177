<template>
  <div class="ui-page" :class="{ 'ui-page-fullwidth' : fullWidth}">
    <div class="ui-page-content" v-if="showContent">
      <slot></slot>
    </div>
    <div class="ui-page-pending" v-else-if="!loginOverlay && !loginDialog">
      <i class="far fa-spinner fa-spin"></i>
    </div>
    <div class="ui-page-loggedout" v-if="loginOverlay">
    </div>
    <slot name="header">
      <UiPageHeader :title="title" :no-back="noBack"/>
    </slot>
    <ui-dialog
      title="You have been logged out"
      v-if="logoutDialog"
      :width="400"
      :no-close="true"
    >
      <p>Due to inactivity, you have been logged out of your account.</p>
      <template #buttons>
        <ui-button :width="150" @click="showLogin">
          Log in again
        </ui-button>
      </template>
    </ui-dialog>
    <div class="ui-page-login" v-if="loginDialog">
      <div class="ui-page-login-inner">
        <app-login default-login @login="didLogin"/>
      </div>
    </div>
  </div>
</template>

<script>

import UiPageHeader from './Page/Header.vue';

export default {
  name: 'UiPage',

  components: {
    UiPageHeader,
  },
  
  props: {
    title: String,
    noBack: Boolean,
    requireLogin: Boolean,
    fullWidth: Boolean,
  },
  
  data() {
    return {
      loginOverlay: false,
      loginDialog: false,
      logoutDialog: false,
      loginInterval: null,
    };
  },

  methods: {
    loggedOut() {
      this.loginOverlay = true;
      this.logoutDialog = true;
    },

    showLogin() {
      this.loginOverlay = false;
      this.logoutDialog = false;
      this.loginDialog = true;
    },

    didLogin() {
      this.loginDialog = false;
    }


  },

  computed: {
    showContent() {
      return !this.requireLogin || (this.$store.getters.initialized && !this.loginDialog);
    }
  },

  mounted() {
    if(this.requireLogin) {
      document.addEventListener('unauthorized', this.loggedOut);

      if(!this.$store.getters.initialized) {
        // TODO: ugly hack, should be done using watchers or events
        this.loginInterval = setInterval(() => {
          if(this.$store.getters.initialized) {
            clearInterval(this.loginInterval);
            if(!this.$store.getters.isLogged) {
              this.showLogin();
            }
          }
        }, 50);
      } else {
        if(!this.$store.getters.isLogged) {
          this.showLogin();
        }
      }
    }
  },

  beforeUnmount() {
    if(this.loginInterval) {
      clearInterval(this.loginInterval);
    }
    if(this.requireLogin) {
      document.removeEventListener('unauthorized', this.loggedOut);
    }
  },
};

</script>

<style>
.ui-page {
  margin-top: 60px
}

.ui-page-content {
  padding: 0 20px;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
}
.ui-page-fullwidth .ui-page-content {
  max-width: 100%;
}

.ui-page-mobile {
  display: none;
}

.ui-page-pending {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  width: 100%;
  flex-direction: column;
  color: #bdc3c7;
  font-size: 96px;
}

.ui-page-loggedout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui-page-login {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui-page-login-inner {
  width: 360px;
}

/*
 * Mobile styles start
 */

@media (max-width: 1024px) {

  .ui-page-content {
    padding: 0 15px;
  }
  .ui-page-login-inner {
    width: 100%;
  }

}

</style>