<template>
  <div class="ui-message ui-message-warning">
    <div class="ui-message-icon">
      <i class="far fa-exclamation-triangle"></i>
    </div>
    <div class="ui-message-text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiMessageWarning'
}
</script>

<style>
.ui-message {
  display: flex;
  border-radius: 4px;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 10px;
}
.ui-message-icon {
  padding-left: 7px;
  font-size: 1.2em;
}
.ui-message-text {
  padding: 3px 7px;
  margin-left: 7px;
  border-left-style: solid;
  border-left-width: 1px;
}

.ui-message-warning {
  border-color: #e67e22;
  background-color: #fff5ec;
}
.ui-message-warning .ui-message-icon {
  color: #e67e22;
}
.ui-message-warning .ui-message-text {
  border-left-color: #e67e22;
}

</style>