<template>
  <div class="ui-mobile-item" :class="{ 'ui-mobile-item-hasicon': !!icon }" @click="$emit('click')">
    <div class="ui-mobile-item-icon">
      <slot name="icon">
        <i :class="icon" v-if="icon"></i>
      </slot>
    </div>
    <div class="ui-mobile-item-body">
      <div class="ui-mobile-item-title ellipsis">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <div class="ui-mobile-item-subtitle">
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </div>
    </div>
    <div class="ui-mobile-item-chevron" v-if="chevron">
      <i class="fal fa-chevron-right"></i>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UiMobileItem',
  props: {
    title: String,
    subtitle: String,
    icon: [String, Boolean],
    chevron: Boolean,
  },

  mounted() {
    
  }
}
</script>

<style>

.ui-mobile-item {
  margin: 0 -15px;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-bottom: 1px solid #bdc3c7;
  gap: 10px;
  width: 100vw;
}
.ui-mobile-item:last-child {
  border-bottom: none;
}

.ui-mobile-item-icon I {
  width: 32px;
  text-align: center;
  font-size: 1.5em;
  color: #7f8c8d;
}

.ui-mobile-item-body {
  width: calc(100% - 35px);
}
.ui-mobile-item-hasicon .ui-mobile-item-body {
  width: calc(100% - 65px);
}
.ui-mobile-item-title {
  font-size: 1.2em;
}
.ui-mobile-item-subtitle {
  color: #7f8c8d;
  font-size: 0.9em;
}

.ui-mobile-item-chevron {
  color: #7f8c8d;
  font-size: 1.5em;
  justify-self: flex-end;
}

</style>