import axios from 'axios';
import store from '../store/index.js';

const API_URL = (window.location.href.indexOf('192.168.88.10') >= 0) ? 'http://192.168.88.10:8888' : 
    (window.location.href.indexOf('ngrok-free.app') >= 0) ? 'https://localhost:8880' : 'https://englishvoice.net.pl';

export default class Api {
    static url() {
        return API_URL;
    }

    static async get(url) {
        try {
            var headers = {};
            if(store.getters.session) headers['Authorization'] = `Bearer ${store.getters.session}`;
            if (window.location.hash.includes('session=')) {
                const sessionHash = window.location.hash.split('session=')[1];
                if (sessionHash) {
                    headers['X-Auth-Session'] = sessionHash;
                }
            }
            const response = await axios.get(API_URL + url, {
                headers: headers,
            }).catch((error) => {
                if("status" in error && error.status == 401) {
                    document.dispatchEvent(
                        new CustomEvent('unauthorized')
                    );
                }
                throw error;
            });
            if (response.headers['x-set-session']) {
                store.commit('session', response.headers['x-set-session']);
            }
            return response.data;
        } catch (error) {
            console.error('Error making GET request:', error);
            throw error;
        }
    }
    static async post(url, data) {
        try {
            var headers = {
                'Content-Type': 'application/json'
            };
            if(store.getters.session) headers['Authorization'] = `Bearer ${store.getters.session}`;
            const response = await axios.post(API_URL + url, data, {
                headers: headers,
            }).catch((error) => {
                if("status" in error && error.status == 401) {
                    document.dispatchEvent(
                        new CustomEvent('unauthorized')
                    );
                }
                throw error;
            });
            if (response.headers['x-set-session']) {
                store.commit('session', response.headers['x-set-session']);
            }
            return response.data;
        } catch (error) {
            console.error('Error making POST request:', error);
            throw error;
        }
    }
}
