<template>
  <div>
    <router-view/>
    <app-update/>
  </div>
</template>

<script>

import api from '@/lib/api.js';

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
    };
  },

  mounted() {
    document.addEventListener('unauthorized', () => {
      this.userUnauthorized();
    });
    this.sessionReload(true);
  },

  methods: {
    sessionReload(interval = false) {
      api.get('/session/get').then((data) => {
        this.$store.commit('assignments', data.assignments);
        this.$store.commit('subscriptionPrices', data.meta.subscriptionPrices);
        this.$store.commit('assignmentNames', data.meta.assignmentNames);
        if(data.user) {
          this.$store.commit('user', data.user);
        }
        this.$store.commit('initialize');
        if(interval) {
          window.setInterval(this.sessionKeepalive, 60000);
        }
      }).catch(() => {});
    },

    sessionKeepalive() {
      if(!this.$store.getters.isLogged) return;
      api.get('/session/keepalive').then((data) => {
        this.$store.commit('subscriptionPrices', data.meta.subscriptionPrices);
        this.$store.commit('assignmentNames', data.meta.assignmentNames);
      }).catch(() => {});
    },

    userUnauthorized() {
      this.$store.commit('logout');
      this.sessionReload(false);
    },
  },
  computed: {
  },
};
</script>

<style>
* {
  box-sizing:border-box;
}

BODY {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}
BODY, INPUT, TEXTAREA {
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-size: 16px;
}

A {
  color: #2980b9;
}

STRONG {
  font-weight: 600;
}

P {
  margin: 8px 0;
}

.ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 1025px) {
  .mobile { display: none !important }
}
@media (max-width: 1024px) {
  .desktop { display: none !important }
}


*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #95a5a6;
  border-radius:999px;
  border: 1px solid white;
}
*::-webkit-scrollbar-thumb:hover {
  border-color: #95a5a6;
}

/* Fix for stupid Font Awesome v6 animation changes */
BODY .fa-spin {
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

</style>
