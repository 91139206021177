<template>
  <div class="assignment-grading">
    <div class="icon">
      <i class="far fa-spinner fa-spin"></i>
    </div>
    <div class="text">
      Grading assignment...
    </div>
    <div class="slider">
      <div class="progress" :style="{ width: `${progress * 100}%` }"></div>
    </div>
    <div class="description">
      {{ description }}
    </div>
  </div>
</template>

<script>

const DESCRIPTIONS = [
  'Uploading your answer',
  'Analyzing task requirements',
  'Checking for spelling errors',
  'Checking for grammar errors',
  'Calculating your score',
  'Downloading the results',
  'Finishing up',
];

export default {
  name: 'AssignmentLoading',

  data() {
    return {
      progressDelta: 0.005,
      progress: 0,
      interval: null,
      description: DESCRIPTIONS[0],
    }
  },

  mounted() {
    this.interval = setInterval(() => {
      this.progress += this.progressDelta;
      if(this.progress >= 1) {
        this.progress = 1;
      }
      if (this.progress >= 0.9) {
        this.progressDelta = 0.0025;
      }
      if (this.progress >= 0.925) {
        this.progressDelta = 0.002;
      }
      if (this.progress >= 0.95) {
        this.progressDelta = 0.001;
      }
      if (this.progress >= 0.975) {
        this.progressDelta = 0.0001;
      }
      this.description = DESCRIPTIONS[Math.floor(this.progress * (DESCRIPTIONS.length-1))];
    }, 100);
  },

  beforeUnmount() {
    clearInterval(this.interval);
  },
}
</script>

<style>
.assignment-grading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  color: #bdc3c7;
}

.assignment-grading .fa-spinner {
  display: block;
  font-size: 96px;
}

.assignment-grading .text {
  margin-top: 16px;
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.assignment-grading .slider {
  border: 1px solid #bdc3c7;
  height: 10px;
  width: 200px;
  border-radius: 999px;
}
.assignment-grading .progress {
  background: #3498db;
  height: 100%;
  border-radius: 999px;
}

.assignment-grading .description {
  margin-top: 3px;
  font-size: 0.85rem;
}

</style>
