<template>
  <ui-page no-back title="IELTS writing practice">
    <div class="view-index">
      <div class="top desktop">
        Prepare for your IELTS writing exam
        with these professional AI tools:
      </div>
      <div class="top mobile">
        Prepare for your IELTS writing exam
        with AI tools:
      </div>
      <div class="columns">
        <div class="column">
          <div class="column-header">
            AI response grading with full scores:
          </div>
          <div class="column-content">
            <img src="@/assets/example_2.png" />
          </div>
        </div>
        <div class="column">
          <div class="column-header">
            AI spelling and grammar checker:
          </div>
          <div class="column-content">
            <img src="@/assets/example_3.png" />
          </div>
        </div>
        <div class="column">
          <div class="column-header">
            AI tutor to guide you in writing:
          </div>
          <div class="column-content">
            <img src="@/assets/example_1.png" />
          </div>
        </div>
      </div>
      <div class="bottom desktop">
        <ui-button huge confirm @click="start">
          Try IELTS writing assignments for free
          <i class="far fa-chevron-right"></i>
        </ui-button>
      </div>
      <ui-mobile-bottom>
        <ui-button confirm @click="start">
          Try it for free
          <!--
          <i class="far fa-chevron-right"></i>
          -->
        </ui-button>
      </ui-mobile-bottom>
    </div>
  </ui-page>
</template>

<script>

import '@/assets/example_1.png';

export default {
  name: 'ViewIndex',

  methods: {
    start() {
      this.$router.push('/choose');
    }
  }
}

</script>

<style>

.view-index .top {
  padding: 20px 0 10px 0;
  text-align: center;
  font-size: 1.5em;
}

.view-index .columns {
  display: flex;
  gap: 10px;
  margin-bottom: 100px;
}

.view-index .column {
  width: 33.3%;
  display: flex;
  flex-direction: column;
}

.view-index .column-header {
  font-size: 1.1em;
  font-weight: 600;
  padding: 15px;
  margin-top: 5px;
  text-align: center;
}

.view-index .column-content {
  padding: 0 20px;
}

.view-index .column-content IMG {
  width: 100%;
  height: auto;
  border: 1px solid #bdc3c7;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.view-index .bottom {
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #bdc3c7;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(3px);
}

/*
 * Mobile styles start
 */ 

@media (max-width: 1024px) {
  .view-index .top {
    text-align: left;
  }
  .view-index .columns {
    flex-direction: column;
  }
  .view-index .column {
    width: 100%;
  }
  .view-index .column-header {
    font-size: 1.1em;
    font-weight: 600;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: left;
  }
  .view-index .column-content {
    padding: 0;
  }
}

</style>