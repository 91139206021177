<template>
  <div class="app-login">

    <div class="app-login-container">
      <div class="app-login-top">
        <ui-control
          type="checkbox"
          v-model="marketing"
          description="I want to receive marketing messages."
          :error="error"
        />
        <ui-button
          :loading="loading"
          @click="setMarketing"
        >
          Change your preferences
        </ui-button>
      </div>
    </div>

  </div>
</template>

<script>
import api from '@/lib/api.js';

export default {
  name: 'AppMarketing',

  data() {
    return {
      marketing: this.$store.getters.receiveMarketing,
      error: null,
      loading: false,
    };
  },

  methods: {
    setMarketing() {
      this.error = null;

      this.loading = true;
      api.post('/user/marketing', {
        marketing: this.marketing,
      }).then((response) => {
        this.loading = false;
        if(response.ok) {
          this.$store.commit('marketing', this.marketing);
          this.$emit('close');
        } else {
          this.error = "Unknown error, please try again";
        }
      }).catch((error) => {
        console.error(error);
        this.loading = false;
        this.error = "Unknown error, please try again";
      });
    },
  }
}

</script>

<style>

</style>
