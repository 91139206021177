<template>
  <div class="view-account-link"
    :class="{ active: active }"
  >
    <i :class="icon"></i>
    {{ title}}
  </div>

</template>

<script>

export default {

  name: 'AccountLink',

  props: {
    active: Boolean,
    icon: String,
    title: String,
  },

  data() {
    return {
      currentLink: ''
    };
  }
};

</script>

<style>

.view-account-link {
  padding: 10px 15px;
  border-radius: 999px;
  cursor: pointer;
  font-size: 1.2em;
  margin-bottom: 5px;
}
.view-account-link.active,
.view-account-link:hover {
    background: #ecf0f1;
}

.view-account-link I {
  width: 20px;
  text-align: center;
  margin-right: 5px;
}



</style>