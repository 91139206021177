<template>
  <div class="assignment-error">
    <div class="icon">
      <i class="fal fa-exclamation-triangle"></i>
    </div>
    <div class="text">
      Error loading assignment
    </div>
    <div class="buttons">
      <ui-button @click="$router.back()">Go back</ui-button>
      <ui-button @click="$emit('retry')">Try again</ui-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssignmentError',
}
</script>

<style>
.assignment-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  color: #d35400;
}

.assignment-error I {
  display: block;
  font-size: 80px;
}

.assignment-error .text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.5rem;
}
.assignment-error .buttons {
  display: flex;
  gap: 10px;
}
</style>
