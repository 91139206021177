<template>

  <div class="ui-mobile-loading mobile">
    <div class="ui-mobile-loading-item">
      <i class="far fa-spinner fa-spin"></i>
    </div>
  </div>

</template>

<script>

export default {
  name: 'UiMobileLoading',
}

</script>

<style>

.ui-mobile-loading {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}

.ui-mobile-loading-item {
  font-size: 3em;
  background: white;
  width: 150px;
  height: 150px;
  color: #7f8c8d;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
