<template>

  <div class="ui-control" :class="'ui-control-'+type">

    <div class="ui-control-label" v-if="label">
      {{ label }}
    </div>

    <div class="ui-control-content">

      <ui-checkbox v-if="type == 'checkbox'" :model-value="modelValue" @update:modelValue="update">
        {{ description }}
      </ui-checkbox>

    </div>

    <div class="ui-control-error" v-if="error">
      {{ error }}
    </div>

  </div>


</template>

<script>

export default {

  name: 'UiControl',

  props: {
    type: String,
    label: String,
    description: String,
    error: String,
    modelValue: [String, Number, Boolean],
  },

  data() {
    console.log(this.modelValue);
    return {};
  },

  methods: {

    update(value) {
      console.log(value);
      this.$emit('update:modelValue', value);
    },
  }

}

</script>

<style>

.ui-control {
  margin-bottom: 5px;
}
.ui-control-checkbox:not(:first-child) {
  margin-top: 10px;
}

.ui-control-label {
  margin-bottom: 3px;
}

.ui-control-error {
  font-size: 0.85em;
  color: #e74c3c;
}

</style>
